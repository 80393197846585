/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button } from 'antd';
import React from 'react'

const JoineeECardModal:React.FC<any> = ({designation, setIsPreviewVisible, closeIcon, form, department, url}) => {
    return (
        <>
            <div className="modalHeader modalHeaderCenter">
                <div className="modalCustomClose">
                    <Button
                        className="linkBtn linkBtn"
                        onClick={() => {
                            setIsPreviewVisible(false);
                        }}
                    >
                        <img src={closeIcon} alt="preview-card" />
                    </Button>
                </div>
                <div style={{ padding: 0, margin: 0, backgroundColor: '#fff' }}>
                    <table style={{
                        maxWidth: '940px', border: 0,
                        width: '100%',
                        alignSelf: 'center'
                    }}>
                        <tr>
                            <td>
                                <table style={{
                                    border: 0,
                                    width: '100%',
                                    alignSelf: 'left'
                                }}>
                                    <tr>
                                        <td style={{ fontSize: '16px', fontFamily: "'Inter', Arial, Helvetica, sans-serif", textAlign: 'left', color: '#2E364C' }}>
                                            <table aria-describedby="Email Welcome" style={{
                                                borderCollapse: 'collapse', border: 0,
                                                width: '100%',
                                                alignSelf: 'left'
                                            }}>
                                                <tr>
                                                    <td style={{
                                                        position: 'relative', verticalAlign: 'bottom', backgroundColor: '#dae1f1',
                                                        backgroundImage: 'url(http://cdn.mcauto-images-production.sendgrid.net/30bf69ebb8191190/b660aa47-4a92-4623-b637-d25d72ba3aab/626x626.png)',
                                                        backgroundRepeat: 'no-repeat', backgroundPosition: '0 0', width: '50%'
                                                    }}>
                                                        <table aria-describedby="Email Welcome" style={{
                                                            borderCollapse: 'collapse',
                                                            backgroundImage: `url(${url})`,
                                                            backgroundRepeat: 'no-repeat',
                                                            backgroundPosition: 'center bottom',
                                                            backgroundSize: 'cover',
                                                            border: 0,
                                                            width: '100%',
                                                            alignSelf: 'left',
                                                        }}>
                                                            <tr>
                                                                <td style={{ height: '400px' }}></td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: 'center', backgroundColor: '#dae1f1', fontWeight: 'bold', textTransform: 'uppercase', padding: '5px' }}>
                                                                    {form.first_name} {form.last_name}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ height: '3px' }}></td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: 'center', backgroundColor: '#dae1f1', padding: '5px' }}>
                                                                    {form.office_email}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ height: '3px' }}></td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: 'center', backgroundColor: '#dae1f1', padding: '5px' }}>
                                                                    {designation}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ height: '3px' }}></td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: 'center', backgroundColor: '#dae1f1', padding: '5px' }}>
                                                                    {department}
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                    <td style={{
                                                        background: '#173753',
                                                        backgroundImage: 'url(http://cdn.mcauto-images-production.sendgrid.net/30bf69ebb8191190/b660aa47-4a92-4623-b637-d25d72ba3aab/626x626.png)',
                                                        backgroundRepeat: 'no-repeat',
                                                        backgroundPosition: 'right 0',
                                                        backgroundSize: '212% 100%',
                                                        width: '50%',
                                                        padding: '20px',
                                                        verticalAlign: 'top'
                                                    }}>
                                                        <table aria-describedby="Email Welcome" style={{ borderCollapse: 'collapse', border: 0, alignSelf: 'left', width: '100%' }}>
                                                            <tr>
                                                                <td style={{ textAlign: 'center' }}>
                                                                    <a href="#" style={{ textDecoration: 'none' }}>
                                                                        <img src="http://cdn.mcauto-images-production.sendgrid.net/30bf69ebb8191190/ca74b2b6-193b-4e66-aad2-884a56967f8c/274x37.png" alt="DA Housekeeping" style={{ outline: 'none', objectFit: 'contain', border: 0 }} width="190" height="39" />
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ height: '50px' }}></td>
                                                            </tr>


                                                            <tr>
                                                                <td style={{ textAlign: 'left', color: '#ffffff', lineHeight: '24px',  whiteSpace: 'pre-wrap', backgroundImage: 'url(http://cdn.mcauto-images-production.sendgrid.net/30bf69ebb8191190/20b8fe41-d4c4-4464-9eea-6d5894a367ea/24x24.png), url(http://cdn.mcauto-images-production.sendgrid.net/30bf69ebb8191190/d8c82f4e-0242-4f18-acd4-9cfb3d602124/28x28.png)', backgroundRepeat: 'no-repeat', backgroundPosition: 'left 0 , right bottom', backgroundSize: '24px, 26px', paddingTop: '30px', paddingBottom: '30px', 'wordBreak': 'break-word' }} >
                                                                    {form.about_candidate}
                                                                </td>
                                                            </tr>


                                                            <tr>
                                                                <td style={{ height: '10px' }}></td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: 'left', color: '#ffffff' }}>
                                                                    - {form.first_name} {form.last_name}
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            {/* <div className="modalCustomFooter">
          <Button
            className="linkBtn linkBtn"
            onClick={() => {
              setIsPreviewVisible(false);
            }}
          >
            {Constants.CANCEL}
          </Button>
        </div> */}

        </>
    )
}

export default JoineeECardModal
