const NoDataFound = () => {
    return (
        <tr className="tableNoResultFound">
            <td colSpan={45}>
                <div className="noResultFound" style={{ textAlign: 'left' }}>No Result Found</div>
            </td>
        </tr>
    )
}

export default NoDataFound
