import { RoutesEnum } from "models/enums";
import { AttendanceData } from "models/interfaces";
import { IApiBaseResponse } from "models/interfaces/Iglobal";
import axiosClient from "sharedServices/api/axios.services";

export class BiometricAttendanceService {
  public getAttendanceReport = async (dateDto: {}) => {
    return axiosClient.get<IApiBaseResponse<AttendanceData>>(
      RoutesEnum.GET_ATTENDANCE_REPORT,
      {
        params: dateDto,
      }
    );
  };
}
