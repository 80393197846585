import { Layout } from 'antd';
import { useRbac } from 'features/rbac/rbac';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Constants } from 'views/constants';
import RejectDrawer from 'utils/regularizeDrawer/RejectDrawer';
import RegularizationListTable from './RegularizationListTable';
import moment from 'moment';
import { usePageTitle } from 'hooks';
import { useApiServices } from 'hooks/useApiServices';


const tableDataStyle = {
  employee_code: {
    minWidth: '100px',
    textAlign: 'center'
  },
  employee_name: {
    minWidth: '200px',
    textAlign: 'left',
  },
  date: {
    minWidth: '120px',
    textAlign: 'left'
  },
  checkin_time: {
    minWidth: '96px',
    textAlign: 'center'
  },
  checkout_time: {
    minWidth: '96px',
    textAlign: 'center'
  },
  reason: {
    minWidth: '150px',
    textAlign: 'left'
  },
  description: {
    minWidth: '300px',
    textAlign: 'left',
    width: '600px'
  },
  status: {
    minWidth: '160px',
    textAlign: 'left'
  },
  task: {
    minWidth: '100px',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}

interface IRegularizationData {
  checkin_time: string;
  checkout_time: string;
  date: string;
  description: string;
  employee_code: string;
  employee_name: string;
  id: number;
  log_id: number;
  reason: string;
  updated_by?: string;
}
interface IRegularizationTableData {
  checkin_time: string;
  checkout_time: string;
  date: string;
  description: string;
  employee_name: string;
  reason: string;
  task: any;
  id: number,
  logId?: number
}



const RegularizationList = () => {

  usePageTitle(Constants.REGULARIZATION_TITLE);

  const defaultValue: IRegularizationTableData = useMemo(() => ({
    checkin_time: '',
    checkout_time: '',
    date: '',
    description: '',
    employee_name: '',
    reason: '',
    task: [],
    id: 0
  }), [])

  // normal page states
  const { name } = useRbac().userDetailsInfo.sub;
  const tableHeaders= useMemo(() => ['employee_name', 'date', 'checkin_time', 'checkout_time', 'reason', 'description', 'task'], []);
  const [tableData, setTableData] = useState<IRegularizationTableData []| null>([]);
  const [selectedRequest, setSelectedRequest] = useState<IRegularizationTableData | null>(null);

  // rejection drawer states
  const [rejectReason, setRejectReason] = useState<string>('');
  const [open, setOpen] = useState(false);
  const { isLoading, data, isError: error, fetchData, updateData } = useApiServices();





  const structureData = useCallback((regularizationData: IRegularizationData []) => {

    if(!regularizationData || regularizationData.length === 0) return null;

    let data = regularizationData?.map((curr_data) => {
      let updatedRegulerizationData = tableHeaders.reduce((acc, header) => {
        let sendingData;
        if (header === 'checkin_time' || header === 'checkout_time') {
          let time: string = curr_data[header] ?? null;
          sendingData = moment(acc['date'] + " " + time).format('hh:mm a')
        }
        else {
          sendingData = curr_data[header] ?? '--'
        }
        acc[header] = sendingData;
        return acc;
      }, defaultValue);
      
      updatedRegulerizationData = {...updatedRegulerizationData, id: curr_data['id'], logId: curr_data['log_id']}
      return updatedRegulerizationData;
    });
    
    return data;
  }, [tableHeaders, defaultValue]);


  const handleApproveOrReject = async (selection: string, selectedElem: any) => {
    let obj = {
      id: selectedElem.id,
      log_id: selectedElem.logId,
      is_approved: selection === 'approved',
      updated_by: name
    }

    if(selection !== 'approved' && selectedElem['rejectReason']){
      obj['rejectReason']= selectedElem['rejectReason']
    }
    setOpen(false);
    await updateData('UPDATE_REGULARIZATION_REQUEST_STATUS', obj)
    await fetchData('GET_REGULARIZATION_REQUEST_LIST')
  }

  function handleChange(e: ChangeEvent<HTMLTextAreaElement> | undefined) {
    if (e) setRejectReason(e?.target.value)
  }

  const cancleReject = () => {
    setOpen(false);
    setRejectReason('');
  }

  function onBtnClick(selection: string, current: any) {
    if (selection === 'approved') {
      handleApproveOrReject('approved', current)
    } else {
      setOpen(true);
      setRejectReason('');
      setSelectedRequest(current)
    }
  }
  

  useEffect(() => {
    fetchData('GET_REGULARIZATION_REQUEST_LIST');
  }, [fetchData]);

  useEffect(() => {
    if(data){
      const updatedData = structureData(data?.regularization_info);
      setTableData(updatedData)
    }
  }, [data, structureData]);



  return (
    <Layout className="regularizationList mainInnerLayout">
      {
        error ? (
          <React.Fragment>
            <h2>{error.code} Error</h2>
            <div className="errorContent">
              <div className="errorContentInner">
                <span className="error-img"></span>
                <div>
                  <h3>{error.message}</h3>
                  <p>{Constants.BACK_TO_PREVIOUS_PAGE}</p>
                </div>
              </div>
            </div>
          </React.Fragment>
        ) :
          (
            <React.Fragment>
              <div className="innerHeader headerSingle">
                <div className="ant-row alignItemsCenter">
                  <div className="ant-col ant-col-xl-16 flx justifyContentBetween alignItemsCenter">
                    <h3 style={{ width: "50%" }}>{Constants.REGULARIZATION_LIST}</h3>
                  </div>
                </div>
              </div>
              <div>
                <RegularizationListTable
                onBtnClick={onBtnClick}
                isTableLoading={isLoading}
                tableHeaders={tableHeaders}
                tableData={tableData ?? []}
                tableDataStyle={tableDataStyle}
                />
              </div>

                {
                  open && (
                    <RejectDrawer
                      open={open}
                      setOpen={setOpen}
                      cancleReject={cancleReject}
                      submitRejectData={handleApproveOrReject}
                      selectedElem={selectedRequest}
                      handleChange={handleChange}
                      rejectData={rejectReason}
                      setRejectData={setRejectReason}
                      title="Regularization"
                    />
                  )
                }
            </React.Fragment>
          )
      }
    </Layout>
  );
}

export default RegularizationList;


