import { RoutesEnum } from "models/enums";
import { TimesheetData } from "models/interfaces";
import { IApiBaseResponse } from "models/interfaces/Iglobal";
import axiosClient from "sharedServices/api/axios.services";

export class TimesheetDataService {
  public getTimesheetDelayData = async (dateDto: {}) => {
    return axiosClient.get<IApiBaseResponse<TimesheetData>>(
      RoutesEnum.GET_TIMESHEET_DATA,
      {
        params: dateDto,
      }
    );
  };
}
