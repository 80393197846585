// export const department_designation_value = {
//     1: [18,19],
//     2: [2,3,4,5,10,11,12,13,14,17,22,23,24,25,26,27,28,29,30],
//     3: [1,6,7,8,15,16,20,21,31,32,33,34,35,36,37,38,39,40,41,42]
// }

export const department_designation_value = {
    1: [1,2],
    2: [5,6,7,8,9,10,11,12,13,26,28,29,30,31,33],
    3: [3,4,14,15,16,17,18,19,20,21,22,23,24,25,32]
}