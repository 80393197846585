import { Button, Select } from "antd";
import React, { useEffect, useState } from "react";
import { LeaveReconcilliationService } from "services";

interface I {
    form: any;
    setForm: Function;
    masterFormVisible: { visible: boolean, text: string; }
    setError: Function;
    signOutWithMessage: Function;
    dropdownData: any[];
    addUpdateOrDeleteLeaveDataInMasterTable: (e: any) => Promise<void>,
    handleClickEditUserInfoCancel: () => void;
    disableAddColumnButton: boolean,
    filteredData: any[]
}

const LeaveReconcilliationMasterForm: React.FC<I> = ({
    form,
    setForm,
    masterFormVisible,
    setError,
    signOutWithMessage,
    dropdownData,
    handleClickEditUserInfoCancel,
    addUpdateOrDeleteLeaveDataInMasterTable, 
    disableAddColumnButton,
    filteredData
}) => {
    const [employeeIDValid, setEmployeeIDValid] = useState<boolean>(true);
    const [timeoutId, setTimeoutId] = useState<any>(null);
    const [checkingEmployeeID, setCheckingEmployeeID] = useState<boolean>(false);
    const columnNumber = disableAddColumnButton? Object.keys(form).length - 2 : Object.keys(form).length - 1


    const capitalize = (str: string) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const getDesignation = (designationId: number) => {
        return dropdownData.find((curr) => curr.value === designationId)?.label;
    };

    const checkEmployeeDataExists = (employee_id: string) => {
        if(employee_id === '') return false;
        return filteredData.find((curr) => curr.employee_id === employee_id)? true : false
    }


    const checkEmployeeID = async (value: any) => {
        try {
            setCheckingEmployeeID(true);
            const {data}: any =
                await new LeaveReconcilliationService().getEmployeeIDExistance({
                    employee_id: value,
                });

            if(Object.keys(data.value).length>0){
                setForm(
                    {
                        ...form,
                        employee_id: value,
                        employee_name: data.value.name,
                        designation: getDesignation(data.value.designation_id),
                        email: data.value.official_email,
                    }
                )
            }
            setEmployeeIDValid(Object.keys(data.value).length>0 ? true : false);
            setError(null);
        } catch (error: any) {
            setError({
                ...error,
                refreshAction: checkEmployeeID,
            });
            if (error.code === 401) {
                signOutWithMessage("You are not authorized");
            }
        }
        finally{
            setCheckingEmployeeID(false);
        }
    }


    const validEmail = (email: string) => {
        if(email.length === 0){
            return true;
        }
        if (email.length !== 0 && email.split('@').length  === 2) {
            
            const validRegex = /^[^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*@digitalavenues\.com$/;


            if (email?.trim().match(validRegex)) {
                return true;
            }

        }
        return false;
    }


    const handleChange = (e: any) => {
        const { id, value, type } = e.target;

        if (id === "employee_id") {
            if(!employeeIDValid)
                setEmployeeIDValid(true)
            setForm((prevForm: any) => {
                if (timeoutId) {
                    clearTimeout(timeoutId);
                }

                if(value.length>0 && !checkEmployeeDataExists(value)){
                    setTimeoutId(setTimeout(() => checkEmployeeID(value), 900));
                }

                return {
                    ...prevForm,
                    [id]: value,
                };
            });
        }
        else if (id === "email") {
            setForm((prevForm: any) => {
                if (timeoutId) {
                    clearTimeout(timeoutId);
                }
                return {
                    ...prevForm,
                    email: value,
                };
            });
        } else {
            setForm((prevForm: any) => {
                return {
                    ...prevForm,
                    [id]: value,
                };
            });
        }
    }
    

    const handleChangeSelect = (value: string, id: string) => {
        let label = dropdownData.find((elem) => elem.value === value);
        label = label && label.label

        setForm((prevForm: any) => {
            return {
                ...prevForm,
                [id]: label,
            };
        });
    }
    

    const enableSave = () => {
        
        if (
            !form?.designation ||
            form?.email?.length === 0 ||
            form?.employee_id?.length === 0 ||
            form?.employee_name?.length === 0 ||
            !employeeIDValid ||
            checkingEmployeeID ||
            form[Object.keys(form)[columnNumber]] === '' ||
            form[Object.keys(form)[columnNumber]] < 0 ||
            form[Object.keys(form)[columnNumber]] === '-0'
        ) {
            return true;
        }
        return false;
    }
    

    const handleFormClose = () => {
        handleClickEditUserInfoCancel();
        setEmployeeIDValid(true);
        setCheckingEmployeeID(false)
    }


    useEffect(() => {
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [timeoutId]);


    return (
        <form onSubmit={addUpdateOrDeleteLeaveDataInMasterTable} id="form">
            <div className="modalCustomBody">
                <div className="editUserInfoForm editEvent modalInnerBg" style={{ padding: "15px" }}>

                    <div className="ant-row">
                        <div className="ant-col ant-col-xl-12">
                            <div className="inputGroup">
                                <label>Employee ID <span className="req">*</span></label>
                                <input
                                    placeholder="DAIPL/1199/000"
                                    type="text"
                                    className={`ant-input ${masterFormVisible.text === 'update' ? 'disableInputBoxStyle' : ''}`}
                                    onChange={handleChange}
                                    id="employee_id"
                                    disabled={masterFormVisible.text === 'update'}
                                    value={form.employee_id}
                                    autoComplete="false"
                                />

                                <label className="validdationWrap error text-warning" style={{ height: "4px" }}  >
                                    {
                                        (masterFormVisible.text === 'add' && form?.employee_id?.length>0)
                                        &&
                                        (
                                            checkEmployeeDataExists(form.employee_id)?
                                                '**Employee ID already exists'
                                                :
                                                checkingEmployeeID?
                                                    'Checking Employee ID ...'
                                                :
                                                employeeIDValid?
                                                    ''
                                                :  
                                                    '**Employee Id does not exist'
                                        )
                                    }
                                </label>
                            </div>
                        </div>

                        <div className="ant-col ant-col-xl-12">
                            <div className="inputGroup">
                                <label>Name <span className="req">*</span></label>
                                <input
                                    placeholder="Name"
                                    type="text"
                                    onChange={handleChange}
                                    id="employee_name"
                                    disabled={masterFormVisible.text === 'add'}
                                    value={capitalize(form.employee_name)}
                                    className="ant-input"
                                    autoComplete="false"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="ant-row">
                        <div className="ant-col ant-col-xl-12">
                            <div className="inputGroup">
                                <label>Office Email <span className="req">*</span></label>
                                {
                                    <>
                                        <input
                                            placeholder="Office Email"
                                            type="email"
                                            onChange={handleChange}
                                            disabled={masterFormVisible.text === 'update' || masterFormVisible.text === 'add'}
                                            id="email"
                                            value={form.email}
                                            className={`ant-input ${masterFormVisible.text === 'update' ? 'disableInputBoxStyle' : ''}`}
                                            autoComplete="false"
                                        />
                                        {/* {
                                            validEmail(form.email) === false &&
                                            (
                                                <label className="validdationWrap error">
                                                    ** Email not valid
                                                </label>
                                            )
                                        } */}
                                    </>
                                }
                            </div>
                        </div>

                        <div className="ant-col ant-col-xl-8">
                            <div className="inputGroup">
                                <label>Designation <span className="req">*</span></label>
                                <Select
                                    placeholder="Select"
                                    options={[...dropdownData]}
                                    onChange={(value) => { handleChangeSelect(value, "designation") }}
                                    value={form.designation}
                                    disabled={masterFormVisible.text === 'add'}
                                    allowClear
                                ></Select>
                            </div>
                        </div>
                    </div>
                    <div className="ant-row">
                        <div className="ant-col ant-col-xl-12">
                            <div className="inputGroup">
                                <label style={{ textTransform: 'capitalize' }}>{Object.keys(form)[columnNumber].split('_').join(' ')} <span className="req">*</span></label>
                                <input
                                    type="number"
                                    step="0.01"
                                    onChange={handleChange}
                                    min={0}
                                    id={Object.keys(form)[columnNumber]}
                                    value={form[Object.keys(form)[columnNumber]]}
                                    className="ant-input"
                                />
                                <label className="validdationWrap error">
                                {
                                    (Number(form[Object.keys(form)[columnNumber]]) < 0
                                    ||
                                    form[Object.keys(form)[columnNumber]] === '-0')?
                                    '** Opening balance can not be negative'
                                    :
                                    ''
                                }
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div className="modalCustomFooter">
                <div className="ant-row">
                    <div className="ant-col ant-col-xl-24">
                        <Button
                            className="linkBtn"
                            onClick={handleFormClose}
                        >
                            CLOSE
                        </Button>
                        <button
                            type="submit"
                            className="ant-btn btnOk"
                            disabled={enableSave()}
                        >
                            SAVE
                        </button>
                    </div>
                </div>
            </div>

        </form>
    );
};


export default LeaveReconcilliationMasterForm;