import React, { useEffect, useState } from "react";
import { Button, Drawer, Space, Tooltip } from "antd";
import { IFilterDrawerInputProps } from "models/interfaces";
import FilterDrawerMultiSelect from "./FilterDrawerMultiSelect";
import FilterDrawerSingleSelect from "./FilterDrawerSingleSelect";
import FilterDrawerCheckbox from "./FilterDrawerCheckbox";

const FilterDrawer: React.FC<{
  disabled?: boolean;
  drawerInputs: IFilterDrawerInputProps[];
  onApplyFilter: (_data: any) => void;
  onResetFilter: (_data: any) => void;
}> = ({ disabled, drawerInputs, onApplyFilter, onResetFilter }) => {

  const [open, setOpen] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState<any>(null);
  const [currentChangedKey, setCurrentChangedKey] = useState<string | null>(
    null
  );
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const _filteredOptions: any = {};
    drawerInputs
      .filter((each) => each.show === undefined || each.show === true)
      .forEach((each) => {
        _filteredOptions[each.keyName] = each.defaultValue
          ? each.defaultValue
          : false;
        if ( each.type === 'singleSelect' && each.defaultValue === null ) {
          _filteredOptions[each.keyName] = null;
        }
        if (each.type === "multiSelect") {
          _filteredOptions[each.keyName] = each.defaultValue
            ? each.defaultValue
            : [];
        }
        if (each.type === "checkbox") {
          _filteredOptions[each.keyName] = each.defaultValue
            ? each.defaultValue
            : false;
        }
      });
    setFilteredOptions(_filteredOptions);
  }, []);

  const doApply = () => {
    onApplyFilter(filteredOptions);
    setOpen(false);
    setIsFilterApplied(true);
  };

  const doReset = () => {
    const _filteredOptions: any = {};
    drawerInputs
      .filter((each) => each.show === undefined || each.show === true)
      .forEach((each) => {
        _filteredOptions[each.keyName] = each.defaultValue
          ? each.defaultValue
          : false;
        if ( each.type === 'singleSelect' && each.defaultValue === null ) {
            _filteredOptions[each.keyName] = null;
        }
        if (each.type === "multiSelect") {
          _filteredOptions[each.keyName] = each.defaultValue
            ? each.defaultValue
            : [];
        }
        if (each.type === "checkbox") {
          _filteredOptions[each.keyName] = each.defaultValue
            ? each.defaultValue
            : false;
        }
      });
    onResetFilter(_filteredOptions);
    setFilteredOptions(_filteredOptions);
    setOpen(false);
    setIsFilterApplied(false);
  };

  const handleInputChange = (_key: string, value: any) => {
    const _filteredOptions: any = { ...filteredOptions };
    _filteredOptions[_key] = value;
    setCurrentChangedKey(_key);
    setFilteredOptions(_filteredOptions);
  };

  const isFilterOptionSelected = () => {
    if (!filteredOptions) {
      return false;
    } else {
      return (
        Object.keys(filteredOptions).filter(
          (each) => filteredOptions[each] !== null
        )?.length > 0
      );
    }
  };

  return (
    <React.Fragment>
      <Button
        type="text"
        className={`dd-btn-toggle filterBtn ${disabled ? "disabled" : ""}`}
        onClick={showDrawer}
        disabled={disabled}
      >
        {isFilterApplied === true && isFilterOptionSelected() ? (
          <div className="dot"></div>
        ) : (
          <></>
        )}
        <i className="da icon-listview listviewIcon"></i>
      </Button>
      <Drawer
        title="FILTER OPTIONS"
        placement="right"
        onClose={onClose}
        visible={open}
        className="filterDrawerWrap"
        closeIcon={
          <Tooltip title="Close Filter Drawer" placement="left">
            <i className="da icon-arrow-right-dark"></i>
          </Tooltip>
        }
      >
        {filteredOptions && (
          <div className="flex-1">
            {drawerInputs
              .filter((each) => each.show === undefined || each.show === true)
              .map((eachInput) => {
                if (eachInput.type === "singleSelect") {
                  return (
                    <FilterDrawerSingleSelect
                      drawerInput={eachInput}
                      filteredOptions={filteredOptions}
                      onChange={handleInputChange}
                      currentChangedKey={currentChangedKey}
                      key={eachInput.keyName}
                    />
                  );
                } else if (eachInput.type === "multiSelect") {
                  return (
                    <FilterDrawerMultiSelect
                      drawerInput={eachInput}
                      filteredOptions={filteredOptions}
                      onChange={handleInputChange}
                      currentChangedKey={currentChangedKey}
                      key={eachInput.keyName}
                    />
                  );
                } else if (eachInput.type === "checkbox") {
                  return (
                    <FilterDrawerCheckbox
                      drawerInput={eachInput}
                      filteredOptions={filteredOptions}
                      onChange={handleInputChange}
                      key={eachInput.keyName}
                    />
                  );
                } else {
                  return null;
                }
              })}
          </div>
        )}

        <Space wrap className="btnsRight">
          <Button type="link" onClick={doReset} className="linkBtn">
            RESET
          </Button>
          <Button
            type="primary"
            onClick={doApply}
          >
            APPLY
          </Button>
        </Space>
      </Drawer>
    </React.Fragment>
  );
};

export default FilterDrawer;
