import React, { useEffect, useState } from "react";
import { Tooltip } from "antd";
import { useRbac } from "features/rbac/rbac";
import TableDataRow from "./TableDataRow";
import "assets/css/customTable.css";
import TableDataFooter from "./TableDataFooter";
import TableDataHeader from "./TableDataHeader";
import LoadingSpinner from "utils/sharedComponents/LoadingSpinner";
import { getRandomValue } from "utils/commonFunction";
import useScrollEffect from "hooks/useScrollEffect";

const CommonReportTable: React.FC<{
  headers: any[];
  tooltipData?: { [key: string]: any };
  data: null | Array<{ [key: string]: any }>;
  headerData?: null | Array<{ [key: string]: any }>;
  footerData?: null | Array<{ [key: string]: any }>;
  onSort?: Function;
  sortField?: { dataIndex: string; order: "asc" | "desc" };
  tableHeight?: { maxHeight: any };
  tableClass?:
    | "customTbl"
    | "table-scroll scroll-y"
    | "customTbl scroll-y main-table"
    | "table-scroll fixedHeaderFooter"
    | "customTbl fixedHeaderFooter"
    | "customTbl scroll-x-y fixedHeaderFooter"
    | "customTbl scroll-y fixedHeaderFooter"
    | "main-table";
  loading?: boolean;
  otherData?: any;
  enableRowSelect?: boolean;
  setConfirmJoin?: Function;
  showEditUserInfoModal?: Function;
  deleteUserFromQueue?: Function;
  setOnboardingStatus?: Function;
  processOffboard?: Function;
}> = ({
  headers,
  tooltipData,
  data,
  headerData,
  footerData,
  onSort,
  sortField,
  tableHeight,
  tableClass,
  loading,
  otherData,
  enableRowSelect,
  setConfirmJoin,
  showEditUserInfoModal,
  deleteUserFromQueue,
  setOnboardingStatus,
  processOffboard
}) => {
  const { hasPermissions } = useRbac();

  const tableScrollableElement = useScrollEffect()
  const [tableBodyData, setTableBodyData] = useState<null | Array<{
    [key: string]: any;
  }>>(null);

  const [selectedRow, setSelectedRow] = useState<null | {
    [key: string]: any;
  }>(null);

  useEffect(() => {
    setTableBodyData(data);
  }, [data]);

  const calculateStickyProperty = (
    currentHeader: any,
    otherStyle?: React.CSSProperties
  ) => {
    const _allHeader = [...headers].filter((eachHeader) => {
      return (
        !eachHeader.allowedPermissions ||
        hasPermissions(eachHeader.allowedPermissions)
      );
    });

    const currentHeaderStyle: React.CSSProperties = {};
    let minWidth = currentHeader.width ? currentHeader.width : null;
    let className = "";
    if (currentHeader.fixed) {
      let prevFieldWidth = 0;
      if (!minWidth) {
        minWidth = 125;
      }
      className = "colLeftSticky";

      const findHeaderFixed = _allHeader.filter(
        (_header) => _header.fixed === currentHeader.fixed
      );
      const findHeaderMatchIndex = findHeaderFixed.indexOf(currentHeader);

      if (findHeaderMatchIndex === findHeaderFixed.length - 1) {
        className = className + " colLeftStickyLast";
      }

      if (findHeaderMatchIndex > 0) {
        prevFieldWidth = _allHeader
          .slice(0, findHeaderMatchIndex)
          .reduce((accumulator, _header) => {
            return _header.width
              ? accumulator + _header.width
              : accumulator + 125;
          }, 0);
      }
      currentHeaderStyle.left = prevFieldWidth;
    }

    if (minWidth) {
      currentHeaderStyle.minWidth = minWidth;
    }

    return {
      style: otherStyle
        ? { ...currentHeaderStyle, ...otherStyle }
        : currentHeaderStyle,
      class: className
    };
  };

  const renderSortIcon = (header: any) => {
    if (!header.canSort) return "";
    if (
      header.dataIndex === sortField?.dataIndex &&
      sortField?.order === "desc"
    ) {
      return <i className="da sortIcon icon-sort-asc" />;
    } else if (
      header.dataIndex === sortField?.dataIndex &&
      sortField?.order === "asc"
    ) {
      return <i className="da sortIcon icon-sort-desc" />;
    }
    return <i className="da sortIcon icon-sort" />;
  };

  const doSort = (sortIndex: string, header: any) => {
    
    if (onSort && header.canSort) {
      let sortOrder = "desc";
      if (sortField?.dataIndex === sortIndex) {
        sortOrder = sortField.order === "desc" ? "asc" : "desc";
      }
      onSort(sortIndex, sortOrder);
    }
  };

  const rowClicked = (singleData: any, canTdSelect: boolean) => {
    if (
      ((enableRowSelect === undefined || enableRowSelect === true) &&
      tableBodyData || canTdSelect)
    ) {
      setSelectedRow(singleData === selectedRow ? null : singleData);
    }
  };

  const tableHeaderToolTip = (currentHeader: any) => {
    if (currentHeader.tooltipText || currentHeader.tooltipIndex) {
      let tooltipTextData = "";

      if (currentHeader.tooltipText) {
        tooltipTextData = currentHeader.tooltipText;
      } else if (currentHeader.tooltipIndex && tooltipData) {
        tooltipTextData = tooltipData
          ? tooltipData[currentHeader.tooltipIndex] ?? ""
          : "";
      }
      return (
        <div className="tblHeadColWrap" style={{ cursor: "default" }}>
          <Tooltip title={tooltipTextData}>
            {currentHeader.titleRender && currentHeader.titleRender()}
            {currentHeader.title}
            {renderSortIcon(currentHeader)}
          </Tooltip>
        </div>
      );
    } else {
      return (
        <div className="tblHeadColWrap">
          {currentHeader.titleRender && currentHeader.titleRender()}
          {currentHeader.title}
          {renderSortIcon(currentHeader)}
        </div>
      );
    }
  };

  return (
    <LoadingSpinner isLoading={loading ? loading : false}>
      <div className="">
        <div
          className={tableClass ? tableClass : "table-scroll fixedHeaderFooter"}
          style={tableHeight}
          ref={tableScrollableElement}
        >
          <table className="main-table">
            <thead>
              <tr>
                {headers.map((eachHeader) => {
                  return (
                    <React.Fragment
                      key={`${eachHeader.dataIndex}_${getRandomValue(
                        "number"
                      )}`}
                    >
                      {(!eachHeader.allowedPermissions ||
                        hasPermissions(eachHeader.allowedPermissions)) && (
                        <th
                          onClick={() =>
                            doSort(eachHeader.dataIndex, eachHeader)
                          }
                          className={`${
                            calculateStickyProperty(eachHeader).class
                          } ${
                            eachHeader.thClassName ? eachHeader.thClassName : ""
                          }`}
                          style={{
                            ...calculateStickyProperty(
                              eachHeader,
                              eachHeader.thStyle
                            ).style,
                          }}
                        >
                          {tableHeaderToolTip(eachHeader)}
                        </th>
                      )}
                    </React.Fragment>
                  );
                })}
              </tr>
              {headerData && headerData.length && (
                <TableDataHeader
                  headers={headers}
                  data={headerData}
                  calculateStickyProperty={calculateStickyProperty}
                  otherData={otherData}
                />
              )}
            </thead>
            <tbody>
              {tableBodyData && (
                <React.Fragment>
                  {tableBodyData.map((eachData, index: number) => (
                    <TableDataRow
                      headers={headers}
                      key={"index-" + index}
                      selectedRow={selectedRow}
                      singleData={eachData}
                      indexVal={index}
                      calculateStickyProperty={calculateStickyProperty}
                      onRowClicked={rowClicked}
                      otherData={otherData}
                      setConfirmJoin={setConfirmJoin}
                      showEditUserInfoModal={showEditUserInfoModal}
                      deleteUserFromQueue={deleteUserFromQueue}
                      setOnboardingStatus={setOnboardingStatus}
                      processOffboard={processOffboard}
                    />
                  ))}
                </React.Fragment>
              )}
              {(!data || data.length === 0) && (
                <tr className="tableNoResultFound">
                  <td colSpan={45}>
                    <div className="noResultFound">No Result Found</div>
                  </td>
                </tr>
              )}
            </tbody>
            {footerData && (
              <TableDataFooter
                headers={headers}
                data={footerData}
                calculateStickyProperty={calculateStickyProperty}
                otherData={otherData}
              />
            )}
          </table>
        </div>
      </div>
    </LoadingSpinner>
  );
};
export default CommonReportTable;
