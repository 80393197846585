import { Layout, Menu, Dropdown } from "antd";
import Logo from "assets/images/logo-full.svg";
import React from "react";
import { isHamMenuVisible, toggleHamMenu } from "models/Slices/GlobalSlice";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useGoogleSignIn, useTokenID } from "utils/auth/google.security";
import { useRbac } from "features/rbac/rbac";
const { Header } = Layout;

export const AppHeader: React.FC<{ headerTitle?: string, breadcrumbTitle?: string, breadcrumbSubtitle?: string }> = () => {
  const { handleSignOut } = useGoogleSignIn();
  const userInfo = useTokenID();
  const { getUserRole } = useRbac();
  const hamMenuActive = useAppSelector(isHamMenuVisible);
  const dispatch = useAppDispatch();

  const userDropdown = (
    <Menu className="userAccountDd">
      <Menu.Item
        key={"logout"}
        onClick={() =>
          handleSignOut()
        }
      >
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <React.Fragment>
    <Header className="header">
      <div className="headerLeft">       
        {/* <a href="#/" className="logo" onClick={(e) => e.preventDefault()}>
          <img src={Logo} alt="" title="Ticket Bash" />
        </a> */}
      </div>
      <ul className="headerRight">
        {/* <li>
          <div>
            <i className="da icon-bell" />
          </div>
        </li>
        <li>
          <div>
            <i className="da icon-question" />
          </div>
        </li>
        <li>
          <div>
            <i className="da icon-user" />
          </div>
        </li> */}
        <li className="username">
          <Dropdown overlay={userDropdown} placement={'bottomRight'}>
              <a href="#/" className="dropdown-btn" onClick={(e) => e.preventDefault()}>
                <span className="dropdown-profile">
                  <span className="userName">{userInfo?.sub?.name}</span>
                  <span className="userRole">{getUserRole()?.role_name}</span>
                </span>
                <i className="da icon-arrow-down" />
              </a>
          </Dropdown>
        </li>
      </ul>
    </Header>
    <div className="dashboardLogoArea">
    <span  className={`hamburgerMenu ${hamMenuActive ? 'active' : ''}`}
    onClick={() => { dispatch(toggleHamMenu(hamMenuActive));
}}><i className="da icon-hamburger" /></span>
    <a href="#/" className="logo" onClick={(e) => e.preventDefault()}>
      <img src={Logo} alt="" title="Ticket Bash" />
    </a>              
  </div>
  </React.Fragment>
  );
};

export default AppHeader;
