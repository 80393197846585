import React  from 'react';
import { Select } from 'antd';
import { IFilterData,ITeamMemberInputProps } from 'models/interfaces/TeamMembers/teamMembers.model';
interface IFilterSingleSelect{
  drawerInput: ITeamMemberInputProps;
  handleChange: (key: string, value: any | undefined) => void;
  filterData: IFilterData;
}
const TeamMembersSingleSelect:React.FC<IFilterSingleSelect> = 
({drawerInput, handleChange, filterData}) => {

  return (
    <div className="inputWrap">
        <label>{drawerInput.label}</label>
        <Select
          placeholder="Please select"
          showSearch={true}
          value={filterData.status}
          onChange={(value: any) => handleChange(drawerInput.keyName, value)}
          style={{ width: "100%" }}
          options={drawerInput?.options ?? []}
          allowClear
        />
      </div>
  )
}
export default TeamMembersSingleSelect;