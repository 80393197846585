import { Layout } from "antd";
import { usePageTitle } from "hooks";
const { Content } = Layout;
export function ServerError() {
  usePageTitle("Error");
  return (
      <Layout className="mainInnerLayout">
        <Content className="mainContainer">
          <div>
            <h3 className="pageError fontQuicksand">
              Sorry, something went wrong. Don’t worry, we’ll fix it soon.
              <br />
              Please try again later.
            </h3>
          </div>
        </Content>
      </Layout>
  );
}
