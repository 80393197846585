import React, { ChangeEvent } from 'react';
import { DatePicker, TimePicker } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { IRegularizeData, IRegularizeDrawerInputProps } from 'models/interfaces/Regularize/regularizeOptions.model';
import type { Moment } from 'moment';


interface IRegularizeInputTextArea{
  drawerInput: IRegularizeDrawerInputProps
  handleChange: (e: ChangeEvent<HTMLTextAreaElement> | undefined) => void
  RegularisationData: IRegularizeData;
  setRegularisationData: React.Dispatch<React.SetStateAction<IRegularizeData>>
}

const RegularizeDrawerTextarea:React.FC<IRegularizeInputTextArea> = 
({drawerInput, handleChange, RegularisationData, setRegularisationData}) => {
  return (
    <React.Fragment>
      <div className="inputWrap">
        <label>{drawerInput?.label || "Describe: "}</label>
        <TextArea 
          style={{color: 'black'}}
          onChange={handleChange} 
          value={RegularisationData.desc}
        />
      </div>
   </React.Fragment>
  )
}

export default RegularizeDrawerTextarea;
