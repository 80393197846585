enum OffsetType {
  YEARS = "y",
  QUARTERS = "Q",
  MONTHS = "M",
  WEEKS = "w",
  DAYS = "d",
  HOURS = "h",
  MINUTES = "m",
  SECONDS = "s",
  MILLISECONDS = "ms",
}

enum OffsetMode {
  SUBTRACT = "subtract",
  ADD = "add",
}

export { OffsetType, OffsetMode };
