import { Select } from 'antd';
import { IWFHData } from 'models/interfaces/WfhApply/wfhApply.model';
import React from 'react'
interface i {
    drawerInput: any;
    wfhData?: IWFHData;
    handleChange: (keyName: string, value: any[]) => void;
    currentChangedKey?: string | null; 
  }
const WfhDrawerMultiselect: React.FC<i> = ({ drawerInput, wfhData, handleChange, currentChangedKey }) => {

    const handleOnChange = (value: string[]) => {
        handleChange(drawerInput.keyName, value);
    };
    
  return (
    <div className="inputWrap">
      <label>{drawerInput.label}:</label>
        <Select
            mode="multiple"
            style={{ width: '100%' }}
            showSearch
            placeholder="Select days"
            optionFilterProp="children"
            value={wfhData?.days?.map((curr: number) => drawerInput.options.find((curr_obj: any) => curr === curr_obj.value))}
            filterSort={(optionA, optionB) =>  {
                if(optionA?.value  && optionB?.value){
                    return (optionA?.value > optionB?.value) ? 1 : -1
                }

                return 1
            }}
            onChange={handleOnChange}
            options={drawerInput.options}
            allowClear
            filterOption={(searchParam: string, curr) => {
                    if(curr && curr.label && typeof(curr.label) == 'string'){
                        return curr?.label?.toLowerCase().includes(searchParam.toLowerCase())
                    }
                    return false;
                }
            }
        />
    </div>
  )
}

export default WfhDrawerMultiselect
