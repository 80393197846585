import React, { useMemo, useState } from 'react'

const EditableCell: React.FC<any> = ({ employee_data, curr_header, tableDataStyle, handleClickInlineUpdate, addUpdateOrDeleteLeaveDataInMasterTable, form, getFormDefault, setForm }) => {
  const [isEditable, setIsEditable] = useState(false);
  const [cellValue, setCellValue] = useState<number | undefined>(undefined);

  const cellValueTemp = useMemo(() => employee_data[curr_header.toLocaleLowerCase()], [employee_data])

  const handleDoubleClick = () => {
    if(!isEditable && cellValue === undefined){
      setCellValue(employee_data[curr_header.toLocaleLowerCase()])
      setIsEditable(true);
      handleClickInlineUpdate(employee_data);
    }
  };


  // Handle outside click to disable the input box
  const handleOutsideClick = async (event: any) => {
    if (isEditable) {
      setIsEditable(false);
      
      if(form[curr_header.toLocaleLowerCase()] && form[curr_header.toLocaleLowerCase()] !== '' && cellValue && cellValue !== cellValueTemp){
        await addUpdateOrDeleteLeaveDataInMasterTable(event);
      }else{
        setForm(getFormDefault())
      }
    }
    setCellValue(undefined)
  };
  
  
  // Handle enter click to disable the input box
  const handleEnterClick = async (event: any) => {
    if (event.key === 'Enter') {
      setIsEditable(false);

      if(form[curr_header.toLocaleLowerCase()] && form[curr_header.toLocaleLowerCase()] !== '' && cellValue && cellValue !== cellValueTemp){
        await addUpdateOrDeleteLeaveDataInMasterTable(event);
      }else{
        setForm(getFormDefault())
      }
    }
    setCellValue(undefined)
  };


  const handleChangeInputValue = (e: any) => {
    const updatedEmpData = {
      ...employee_data,
      [curr_header.toLocaleLowerCase()]: e.target.value === ''? cellValueTemp : Number(e.target.value)
    }
    handleClickInlineUpdate(updatedEmpData);
    setCellValue(e.target.value === ''? undefined : Number(e.target.value)); 
  }


  return (
    <td
      onDoubleClick={handleDoubleClick}
      className={`text-right`}
      style={{ ...tableDataStyle[curr_header.toLocaleLowerCase()], width: '200px', padding: `${isEditable? '0px' : '5px 8px'}` }}
      key={curr_header}>
        {
          isEditable?
            <input
            type="number"
            step="0.25"
            onBlur={handleOutsideClick}
            onKeyDown={handleEnterClick}
            style={{ width: '100%', height: '28.33px', padding: '6px', minHeight: '0' }}
            className={`${isEditable ? '' : 'not-editable'}`}
            value={cellValue}
            autoFocus
            onChange={handleChangeInputValue}
            onWheel={(e: any) => e.target.blur()}
          />
          :
          cellValue || cellValueTemp
        }
    </td>
  )
}

export default EditableCell
