import React, { ErrorInfo, useState } from "react";
import AppHeader from "./AppHeader";
import { Layout } from "antd";
import AppLeftPanel from "./AppLeftPanel";
import { useAppSelector } from "app/hooks";
import { isLeftPanelVisible } from "models/Slices/GlobalSlice";
import { LeftNavRoutesEnum } from "models/enums/leftNavRoutes";
import { useLocation } from "react-router-dom";
import { verifyTokenID } from "utils/auth/google.security";

class ErrorBoundary extends React.Component<{}, { hasError: boolean }> {
  constructor(props: {}) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ hasError: true });

    if (
      process.env.REACT_APP_ENVIRONMENT !== "dev" &&
      process.env.REACT_APP_ENVIRONMENT !== "local"
    ) {
      window.location.href = "/server-error";
    }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }
    return this.props.children;
  }
}

const AppLayout: React.FC<{}> = (props) => {
  const leftPanelActive = useAppSelector(isLeftPanelVisible);
  let classes = "";
  leftPanelActive && (classes = "leftPanelCollapsed");
  const [defaultOpenKeys, setDefaultOpenKeys] = useState<string[]>([
    LeftNavRoutesEnum.ONBOARDING,
  ]);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([
    LeftNavRoutesEnum.ONBOARDING,
  ]);
  const location = useLocation();

  const path = location.pathname.split("/");

  return (
    verifyTokenID() ? (
      <React.Fragment>
        <div className={`mainWrapper ${classes ? classes : ""}`}>

          <ErrorBoundary>
            <div className="bodyWrapper">
              <AppLeftPanel
                defaultOpenKeys={defaultOpenKeys}
                setDefaultOpenKeys={setDefaultOpenKeys}
                selectedKeys={selectedKeys}
                setSelectedKeys={setSelectedKeys}
              />
              <Layout className="contentMainLayout">
                <AppHeader
                  headerTitle={"/"}
                  breadcrumbTitle={path[1]}
                  breadcrumbSubtitle={path[2]}
                />
                {props.children}
              </Layout>
            </div>
          </ErrorBoundary>
        </div>
      </React.Fragment>
    ) : (
      <div className={`${classes ? classes : ""}`}>
        <ErrorBoundary>
          <div className="bodyWrapper">
            <Layout>{props.children}</Layout>
          </div>
        </ErrorBoundary>
      </div>
    )
  );
};

export default AppLayout;
