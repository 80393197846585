import {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import { ICustomError } from "models/interfaces/ICustomError";
import { getSecondsDiff } from "utils/formatTimePeriod";
import axiosClient from "sharedServices/api/axios.services";
import { IApiBaseResponse } from "models/interfaces/Iglobal";
import { RoutesEnum } from "models/enums";
import { auth } from "utils/auth/google.security";
import { getEncodedData } from "utils/commonFunction";
import jwtDecode from "jwt-decode";

const isAPIDown = (err: AxiosError) => {
  return !!err.isAxiosError && !err.response;
};

const onRequest = async (
  config: AxiosRequestConfig
): Promise<AxiosRequestConfig> => {
  // change the base url and append the additional headers coming with request
  config = {
    ...config,
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      ...config.headers,
      ...{
        Authorization:
          config.url !== "/generate-token"
            ? `Bearer ${await getAccessToken()}`
            : "",
      },
    },
  };
  return config;
};

const getAccessToken = async () => {
  const timeout = window.localStorage.getItem("expirationTime");
  const email = window.localStorage.getItem("emailId");
  const name = window.localStorage.getItem("displayName");

  if (!timeout) {
    return "";
  }

  const diff = getSecondsDiff(
    parseInt(timeout),
    Math.floor(Date.now() / 1000)
  ) as number;

  if (timeout && (diff < 600) && (diff > 0)) {
    const rep: any = await axiosClient.post<IApiBaseResponse<any>>(
      RoutesEnum.GENERATE_AUTHTOKEN,
      {
        email_id: email,
        display_name: name,
      }
    );
    const idToken: string = rep.data.access_token;
    if (idToken) {
      try {
        const decodedAuth: any = jwtDecode(idToken);
        let expirationTime = decodedAuth.exp;
        window.localStorage.setItem("expirationTime", expirationTime);
        window.localStorage.setItem("authToken", idToken);
      } catch (error) {
        console.error(error);
      }
    }
    return idToken;
  } else if (timeout && (diff < 0)) {
    auth.signOut();
    window.localStorage.removeItem("emailId");
    window.localStorage.removeItem("displayName");
    window.localStorage.removeItem("expirationTime");
    window.localStorage.removeItem("authToken");
    const msg = getEncodedData("Your session expired");
    window.location.replace(`/login?msg=${msg}`);
  } else {
    return window.localStorage.getItem("authToken");
  }
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse) => {
  return response;
};

const onResponseError = (error: AxiosError): Promise<ICustomError> => {
  if (isAPIDown(error)) {
    return Promise.reject({
      code: 503,
      message: "Service Unavailable",
      error: error,
    });
  } else if (error.response?.status === 403) {
    return Promise.reject({
      code: 403,
      message: "Access Denied",
      error: error,
    });
  } else if (error.response?.status === 401) {
    return Promise.reject({ code: 401, message: "Unauthorized", error: error });
  }
  return Promise.reject({
    code: error.response?.status,
    message: error.message,
    error: error,
  });
};

export function setupInterceptorsTo(
  axiosInstance: AxiosInstance
): AxiosInstance {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
}
