import useScrollEffect from 'hooks/useScrollEffect';
import moment from 'moment';
import React from 'react'
import LoadingSpinner from 'utils/sharedComponents/LoadingSpinner';
import NoDataFound from 'views/NoDataFound'

const colStyles = {
    name: { left: "0", minWidth: "180px" },
    grand_total: { left: "0", minWidth: "120px", width: '100px' },
    restOfTheColumns: { minWidth: "90px", }
}
interface ITableData {
    employee_name: string
    bg_color: string | null,
    grandTotal: string,
    totalHoursData: object,
    working_status: string | null,
}
interface ITableProps {
    tableHeaders: string[];
    tableData: any[];
    getSortArray: (arrayToSort: any[], sort: {keyToSort: string; direction: string;}) => any[];
    isLoading: boolean,
    currButtonState: 'Total Hours' | 'In/ Out'
}


const CommonTable: React.FC<ITableProps> = ({ tableHeaders, tableData, getSortArray, isLoading, currButtonState }) => {
    const tableScrollableElement = useScrollEffect()
    console.log(currButtonState);
    let alignment = 'text-center'
    if(currButtonState === 'Total Hours')
        alignment = 'text-left'
    

    return (
        <LoadingSpinner isLoading={isLoading}>
            <div className="customTbl userManagementTbl scroll-y tblStickyTHead main-table timeDelayReport" ref={tableScrollableElement}>
            <table className='totalHrReportTbl'>
                <thead>
                    <tr>
                        {
                            tableHeaders?.map((curr_header: string) => {
                                
                                return (
                                    <th className={` ${curr_header === 'name' ? 'text-left colLeftSticky colLeftStickyLast' : alignment}`}
                                        style={{ ...colStyles[curr_header === 'name' || curr_header === 'grand_total' ? curr_header : 'restOfTheColumns'] }}
                                        key={curr_header}>
                                        {curr_header.split('_').join(' ')}
                                    </th>
                                )
                            })
                        }
                        <th className='colLeftSticky text-left' style={{ left: "0", minWidth: "20px" }}></th>
                    </tr>
                </thead>

                <tbody>
                    {
                        tableData?.map(({ employee_name, totalHoursData, working_status, empWfhDates }: any) => {
                            
                            return (
                                <tr key={employee_name} >
                                    {
                                        tableHeaders?.map((curr_header: string, index: number) => {
                                            let is_duration_zero = true;
                                            const cellValue = totalHoursData[curr_header];
                                            const isTempWfh = empWfhDates?.includes(curr_header);
                                            let contentToShow = null
                                            let classNames = curr_header === 'name' ? 'text-left colLeftSticky colLeftStickyLast' : (curr_header === 'grand_total') ? alignment : `${alignment} highlighted`;
                                            const styles = { ...colStyles[curr_header] || { left: 0, minWidth: '50px' } }

                                            if (curr_header === 'name' || curr_header === 'grand_total') {
                                                return (
                                                    <td key={index} className={classNames}
                                                        style={styles}>
                                                        <span> {(working_status === 'Inactive' && curr_header !== 'name') ? '0h 00m' : totalHoursData[curr_header]} </span>
                                                    </td>
                                                )
                                            }

                                            let cellStyle: Object = {display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: 0};

                                            if(typeof(cellValue) === 'string'){
                                                contentToShow = <>{cellValue}</>;
                                                const work_type = isTempWfh? 'Short Term WFH' : working_status ?? 'Team Member';
                                                classNames = classNames + ' highlighted-' + work_type?.toLowerCase()?.split(' ')?.join('-');
                                                is_duration_zero = cellValue === '0h 00m';
                                                cellStyle = {}
                                            }
                                            else if(cellValue?.in_time && cellValue?.out_time){
                                                contentToShow = (
                                                    <>
                                                        <span>{moment(cellValue?.in_time, "HH:mm:ss").format("hh:mm a")}</span>
                                                        <span>{moment(cellValue?.out_time, "HH:mm:ss").format("hh:mm a")}</span>
                                                    </>
                                                )
                                            }
                                            else if(cellValue?.in_time || cellValue?.out_time){
                                                contentToShow = (
                                                    <>
                                                        <span>{cellValue?.in_time? moment(cellValue?.in_time, "HH:mm:ss").format("hh:mm a") : '-'}</span>
                                                        <span>{cellValue?.out_time? moment(cellValue?.out_time, "HH:mm:ss").format("hh:mm a") : '-'}</span>
                                                    </>
                                                )
                                            }

                                            


                                            if (working_status === 'Inactive' || !cellValue) {
                                                return (
                                                    <td key={index} className={`${working_status === 'Inactive' ? classNames : 'highlighted'}`}
                                                        style={styles}>
                                                        <span></span>
                                                    </td>
                                                )
                                            }
                                            

                                            return (
                                                <td key={index} className={`${is_duration_zero ? classNames : 'highlighted'}`}
                                                    style={styles}>
                                                    <span style={cellStyle}>{contentToShow}</span>
                                                </td>
                                            )
                                        })
                                    }
                                    <td></td>
                                </tr>
                            )
                        })
                    }

                    {(!tableData || tableData.length === 0) && <NoDataFound />}
                </tbody>
                <tfoot></tfoot>
            </table>
            </div>
        </LoadingSpinner>
    )
}

export default CommonTable;
