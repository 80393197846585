import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useState } from 'react';
import { getFormattedDate, getIconClass, getSortArray } from 'utils/commonFunction';
import LoadingSpinner from 'utils/sharedComponents/LoadingSpinner';
import NoDataFound from 'views/NoDataFound';

interface IRegularizationDetailsTable {
    isTableLoading: boolean,
    tableHeaders: string[]
    tableData: any[]
    tableDataStyle: any
    onBtnClick: any
}



const RegularizationListTable: React.FC<IRegularizationDetailsTable> =
({ isTableLoading, tableHeaders, tableData, tableDataStyle, onBtnClick }) => {

        const [sort, setSort] = useState<{ keyToSort: string, direction: string }>({ keyToSort: "employee_name", direction: "asc" });

        function handleSortClick(header: string) {
            let direction = 'desc';
            if(header === sort.keyToSort && sort.direction !== 'asc'){
                direction = 'asc';
            }

            setSort({
                keyToSort: header,
                direction
            })
        };


        return (
            <LoadingSpinner isLoading={isTableLoading}>
                <div className="customTbl userManagementTbl scroll-y tblStickyTHead timeDelayReport">
                    <table className="main-table">
                        <thead style={{ width: '100%' }}>
                            <tr>
                                {
                                    tableHeaders.map((curr_header: any) => {
                                        if (curr_header === 'task') {
                                            return (<th style={{ minWidth: '100px', textAlign: 'center', }} key={curr_header}></th>)
                                        }
                                        return (
                                            <th
                                                style={{ ...tableDataStyle[curr_header] }}
                                                key={curr_header}>
                                                {curr_header.split('_').join(" ")}
                                                {
                                                    (curr_header === 'employee_name' || curr_header === 'date')
                                                    &&
                                                    (
                                                        <Button 
                                                        style={{border: 'none', outline: 'none', background: 'transparent', padding: '0', margin: '0', 'marginLeft': '5px' }}
                                                        onClick={() => handleSortClick(curr_header)}
                                                        >
                                                            <span style={{ 'marginLeft': '5px' }} className={getIconClass(curr_header, sort)}></span>
                                                        </Button>
                                                        
                                                    )
                                                }
                                            </th>
                                        )
                                    })
                                }
                            </tr>
                        </thead>

                        <tbody>
                            {
                                getSortArray(tableData, sort)?.map((currData: any) => {
                                    // console.log(currData);
                                    
                                    return (
                                        <tr key={currData.id}>
                                            {
                                                tableHeaders?.map((header) => {
                                                    let dataToShow = currData[header] || '';
                                                    if(header === 'date'){
                                                        dataToShow = getFormattedDate(dataToShow, 'MMMM D, YYYY')
                                                    }
                                                    if (header === 'task') {
                                                        return (
                                                            <td key={currData[header]}
                                                                style={{ ...tableDataStyle[header] }}>
                                                                <span>
                                                                    <div className='flx' id='regularization-button-container'>
                                                                        <Button
                                                                            className='regularization-button'
                                                                            id='regularization-approve'
                                                                            onClick={() => onBtnClick('approved', currData)}
                                                                        >
                                                                            <CheckOutlined style={{ color: 'white' }} />
                                                                        </Button>
                                                                        <Button
                                                                            className='regularization-button'
                                                                            id='regularization-reject'
                                                                            onClick={() => onBtnClick('rejected', currData)}
                                                                        >
                                                                            <CloseOutlined style={{ color: 'white' }} />
                                                                        </Button>
                                                                    </div>
                                                                </span>
                                                            </td>
                                                        )
                                                    }

                                                    return (
                                                        <td key={currData[header]}
                                                            style={{ ...tableDataStyle[header] }}>
                                                            <span>{dataToShow}</span>
                                                        </td>
                                                    )
                                                })
                                            }
                                        </tr>
                                    )
                                })
                            }

                            {(!tableData || tableData.length === 0) && <NoDataFound />}
                        </tbody>

                        <tfoot></tfoot>
                    </table>
                </div>
            </LoadingSpinner>
        )
    }

export default RegularizationListTable;
