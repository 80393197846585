import { IApiBaseResponse } from "models/interfaces/Iglobal";
import axiosClient from "sharedServices/api/axios.services";
import { RoutesEnum } from "models/enums";
import {RecruitDateRange, TicketsDurationInfo} from "models/interfaces";

export class RecruiteBoardTicketDuration {
    public getTicketList = async (params: RecruitDateRange['data']) => {
      return axiosClient.get<IApiBaseResponse<TicketsDurationInfo>>(
        RoutesEnum.RECRUITE_BOARD_DURATION,
        {
          params: params,
        }
    );
    }
};