import { Col, Row, Select } from "antd"

const OnboardingModalHeader = (props: any) => {
    const { modalTitle, canGiveImmediateAccessOfAllCredentialsToJoinee, form, accessOption, onChangeAccessCredentials } = props;
    if (canGiveImmediateAccessOfAllCredentialsToJoinee(form.joinee_id, form.date_of_joining, form.is_confirmed)) {
        return <Row>
            <Col span={8}><div className="modalHeader">{modalTitle}</div></Col>
            <Col span={6} offset={10}>
                <div className="inputGroup">
                    <Select
                        className="selectDropdownForTbl"
                        placeholder="Select"
                        options={[
                            { label: "Provide All Access Credentials", value: 'generate-all-access' },
                            { label: "Invite to Zoho", value: 'invite-to-zoho' },
                            { label: "Invite to Jira", value: 'invite-to-jira' },
                        ]}
                        value={accessOption}
                        onChange={(value) => onChangeAccessCredentials(value)}
                    ></Select>
                </div>
            </Col>
        </Row>
    } else {
        return <div className="modalHeader">{modalTitle}</div>
    }

}

export default OnboardingModalHeader;