import { RoutesEnum } from "models/enums";
import { IApiBaseResponse } from "models/interfaces/Iglobal";
import axiosClient from "sharedServices/api/axios.services";

export class RegularizationRequestService {
  public createRegularisationRequest = async (RegularisationRequestData: {}) => {
    return axiosClient.post<IApiBaseResponse<any>>(
      RoutesEnum.POST_REGULARISATION_REQUEST,
      RegularisationRequestData
    );
  };
  public getRegularizationRequest = async (dateDto: {}) => {
    return axiosClient.get<IApiBaseResponse<any>>(
      RoutesEnum.GET_REGULARIZATION_DETAILS,
      {
        params: dateDto,
      }
    );
  };

  public updateRegularizationRequest = async (dateDto: {}) => {
    return axiosClient.post<IApiBaseResponse<any>>(
      RoutesEnum.UPDATE_REGULARIZATION_STATUS,
      {
        ...dateDto,
      }
    );
  };

  public getSpecificRegularizeRequest = async (params: string) => {
    return axiosClient.get<IApiBaseResponse<any>>(
      RoutesEnum.GET_SPECIFIC_REGULARIZATION_REQUEST + params
  );
  }
}