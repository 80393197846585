import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import globalReducer from "models/Slices/GlobalSlice";
import filterReducer from "models/Slices/FilterSlice";

export const store = configureStore({
  reducer: {
    global: globalReducer,
    filters: filterReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
