export class Constants {
    
    // TITLE
    static readonly ONBOARDING_TITLE = "Onboarding | DA Housekeeping";
    static readonly OFFBOARDING_TITLE = "Offboarding | DA Housekeeping";
    static readonly UPLOAD_DOCUMENTS_TITLE = "Upload Documents | DA Housekeeping";
    static readonly TEAM_MEMBERS_LIST = "Team Members | DA Housekeeping";
    
    static readonly RECRUITMENT_TITLE = "Recruitment | DA Housekeeping";
    static readonly TIMESHEET_TITLE = "Timesheet Delay | DA Housekeeping";
    static readonly TOTAL_HOURS_TITLE = "Total Hours | DA Housekeeping";    
    
    static readonly DAILY_ATTENDANCE_TITLE = "Daily Attendance | DA Housekeeping";
    static readonly REGULARIZATION_TITLE = "Regularization List | DA Housekeeping";
    static readonly SPECIFIC_REGULARIZATION_TITLE = "Regularization Request | DA Housekeeping";
    static readonly UPLOAD_ATTENDANCE_DOCUMENTS_TITLE = "Upload Attendance Documents | DA Housekeeping";
    
    static readonly LEAVE_RECONCILIATION_MASTER_TITLE = "Leave Reconciliaion Master | DA Housekeeping";
    static readonly LEAVE_REPORT_TITLE = "Leave Report | DA Housekeeping";
    static readonly UPLOAD_LEAVE_DOCUMENTS_TITLE = "Upload Leave Documents | DA Housekeeping";
    
    static readonly HISTORY_WFH_TITLE = "History WFH Requests | DA Housekeeping";
    static readonly WFH_LIST_TITLE = "Work from Home Requests | DA Housekeeping";
    static readonly NEW_WORK_FROM_HOME_REQUEST_TITLE = "New Work From Request | DA Housekeeping";
    



    // PAGE NAME
    static readonly ONBOARDING = "Onboarding";
    static readonly OFFBOARDING = "Offboarding";
    static readonly TEAM_MEMBERS = "Team Members";
    static readonly UPLOAD_DOCUMENTS = "Upload Documents"

    static readonly RECRUITMENT_BOARD = "Recruitment Board";
    static readonly TIMESHEET_REPORT = "Timesheet Delay";
    static readonly TOTAL_HOURS_REPORT = "Total Hours"
    
    static readonly BIOMETRIC_DAILY_ATTENDANCE = "Daily Attendance";
    static readonly REGULARIZATION_LIST = "Regularization List";
    static readonly SPECIFIC_REGULARIZATION_REQUEST = "Regularization Request";
    static readonly UPLOAD_ATTENDANCE_DOCUMENTS = "Upload Attendance Documents";
    
    static readonly LEAVE_RECONCILIATION_MASTER_SHEET = "Master Reconciliation";
    static readonly LEAVE_REPORT = "Leave Reconciliation";
    static readonly UPLOAD_LEAVE_DOCUMENTS = "Upload Leave Documents";

    static readonly WFH_HISTORY = 'WFH History'
    static readonly WFH_LIST = 'Pending Requests'
    static readonly SPECIFIC_WFH_REQUEST = "Work From Home Request";
    
    
    
    static readonly SEARCH_LABEL_LEAVE_RECONCILIATION = "Search Name";
    static readonly SEARCH_LABEL_MASTER_RECONCILIATION = "Search Name, Designation and Email";
    static readonly SEARCH_LABEL_TOTAL_HOURS_REPORT = "Search Name ";
    
    static readonly TEAM_MEMBER_FILTER_DRAWER = "Team Members Status";
    static readonly  HISTORY_WFH_FILTER_DRAWER = "Work From Home";
    // Additional constants
    static readonly STATUS_UPDATING_MESSAGE = "Updating joiner status...";
    static readonly STATUS_UPDATED_MESSAGE = "Joiner status updated successfully!";
    static readonly STATUS_UPDATE_FAILED = "Joiner status not saved!";

    static readonly ACCESS_CREDENTIALS_CREATION_MESSAGE = "Creating access crendentials...";
    static readonly ACCESS_CREDENTIALS_CREATION_SUCCESS = "Access crendentials created successfully!";
    static readonly ACCESS_CREDENTIALS_CREATION_FAILED = "Access crendentials creation failed!";

    static readonly ACCESS_CREDENTIALS_DELETION_MESSAGE = "Deleting placeholder...";
    static readonly ACCESS_CREDENTIALS_DELETION_SUCCESS = "placeholder deleted successfully!";
    static readonly ACCESS_CREDENTIALS_DELETION_FAILED = "placeholder deletion failed!";

    static readonly COMPLETE_EMPLOYEE_OFFBOARDING_MESSAGE = "Completing employee offboarding...";
    static readonly COMPLETE_EMPLOYEE_OFFBOARDING_SUCCESS = "Employee offboarding completed successfully!";
    static readonly COMPLETE_EMPLOYEE_OFFBOARDING_FAILED = "Employe offboarding completion failed.";

    static readonly PROCESS_EMPLOYEE_OFFBOARDING_MESSAGE = "Processing employee offboarding...";
    static readonly PROCESS_EMPLOYEE_OFFBOARDING_SUCCESS = "Employee offboarding processed successfully!";
    static readonly PROCESS_EMPLOYEE_OFFBOARDING_FAILED = "Employe offboarding process failed.";


    static readonly DELETE_USER_MESSAGE = "Deleting user information";
    static readonly DELETED_USER_MESSAGE = "User deleted successfully!";

    static readonly DELETE_USER_CONFIRMATION_MESSAGE_OPEN = "Are you sure you want to";
    static readonly DELETE_USER_CONFIRMATION_MESSAGE_CLOSE = "fullname's data?";

    static readonly USER_DELETION_FAILED = "Failed to delete user";

    static readonly BACK_TO_PREVIOUS_PAGE = "Try going back to the previous page.";

    static readonly NEW_TEAM_MEMBERS_LIST = "New Team Members List";
    static readonly EXIT_QUEUE = "Exit Queue"

    static readonly SEARCH_LABEL = "Search Name, Mobile No., Office and Personal Email";
    static readonly SEARCH_LABEL_EXIT = "Search Name and Office Email";

    static readonly ADD_NEW_MEMBER_INFO = "Add New Member Info";
    static readonly ADD_MEMBER_INFO = "Add Member Info";

    static readonly NEW_MEMBER = "New Member";
    static readonly MEMBER = "Member";

    static readonly CONFIRM_JOINER_MESSAGE_OPEN = "Are you sure you want to confirm";
    static readonly CONFIRM_JOINER_MESSAGE_CLOSE = "for this Joiner?";
    static readonly RESEND_UPLOAD_DOCUMENT_MESSAGE_OPEN = "Are you sure you want to";
    static readonly RESEND_UPLOAD_DOCUMENT_MESSAGE_CLOSE = "the Submit Document Email to this Joiner?";
    static readonly UPDATE_ONBOARDING_STATUS_MESSAGE_OPEN = "Are you sure you want to";
    static readonly UPDATE_ONBOARDING_STATUS_MESSAGE_CLOSE = "the Onboarding Status for this Joiner?";
    static readonly OK = "OK";
    static readonly CANCEL = "CANCEL";
}
