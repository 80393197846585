import React, { useEffect, useState } from "react";
import { Button, Drawer, Space, Tooltip } from "antd";
import { IFilterDrawerInputProps } from "models/interfaces";
import RecruitDrawerMultiSelect from './RecruitDrawerMultiSelect'
import FilterDrawerSingleSelect from "./FilterDrawerSingleSelect";
import FilterDrawerCheckbox from "./FilterDrawerCheckbox";
import RecruitDrawerText from "./RecruitDrawerText";

const RecruitmentDrawer: React.FC<{
  disabled?: boolean;
  drawerInputs: IFilterDrawerInputProps[];
  onApplyFilter: (_data: any) => void;
  onResetFilter: (_data: any) => void;
  filteredOptions : any[];
  setFilteredOptions: React.Dispatch<any>;
  currentChangedKey: string | null;
  setCurrentChangedKey: React.Dispatch<any>;
  doReset: ()=> void;
  open: boolean,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  
}> = ({ disabled, drawerInputs, onApplyFilter, onResetFilter, filteredOptions, setFilteredOptions, currentChangedKey, setCurrentChangedKey,doReset, open, setOpen }) => {

  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  
  const onClose = () => {
    setOpen(false);
  };

  
  useEffect(() => {
    const _filteredOptions: any = {};
    drawerInputs
      .filter((each) => each.show === true)
      .forEach((each: any) => {
        if ( each.type === 'singleSelect' && each.defaultValue === null ) {
          _filteredOptions[each.keyName] = null;
      }
        if (each.type === "multiSelect") {
          _filteredOptions[each.keyName] = each.defaultValue
            ? each.defaultValue
            : [];
        }
        if (each.type === 'text') {
          _filteredOptions[each.keyName] = 0;
      }

      });

    setFilteredOptions(_filteredOptions);
  }, []);

  const doApply = () => {
    onApplyFilter(filteredOptions);
    setOpen(false);
    setIsFilterApplied(true);
  };


  const handleInputChange = (_key: string, value: any) => {
    const _filteredOptions: any = { ...filteredOptions };
    if((value === undefined || value === null)){
      
      _filteredOptions[_key] = null;
      if(_key==='Filtered Columns' && (value === undefined || value === null)){
        _filteredOptions['Days'] = 0;
      }
    }else{

      _filteredOptions[_key] = value;
    }
    setCurrentChangedKey(_key);
    setFilteredOptions(_filteredOptions);
  };

  const isFilterOptionSelected = () => {
    if (filteredOptions['Show Columns'].length === 0 && !filteredOptions['Filtered Columns']) {
      return false;
    } else {
      return (
        Object.keys(filteredOptions).filter(
          (each) => filteredOptions[each] !== null
        )?.length > 0
      );
    }
  };

  
  
  return (
    <React.Fragment>
      <Button
        type="text"
        className={`dd-btn-toggle filterBtn ${disabled ? "disabled" : ""}`}
        onClick={showDrawer}
        disabled={disabled}
      >
        {isFilterApplied === true && isFilterOptionSelected() ? (
          <div className="dot"></div>
        ) : (
          <></>
        )}
        <i className="da icon-listview listviewIcon"></i>
      </Button>
      <Drawer
        title="FILTER OPTIONS"
        placement="right"
        onClose={onClose}
        visible={open}
        className="filterDrawerWrap"
        closeIcon={
          <Tooltip title="Close Filter Drawer" placement="left">
            <i className="da icon-arrow-right-dark"></i>
          </Tooltip>
        }
      >
        {filteredOptions && (
          <div className="flex-1">
            {drawerInputs
              .filter((each) => each.show === undefined || each.show === true)
              .map((eachInput) => {
                if (eachInput.type === "singleSelect") {
                  return (
                    <FilterDrawerSingleSelect
                      drawerInput={eachInput}
                      filteredOptions={filteredOptions}
                      onChange={handleInputChange}
                      currentChangedKey={currentChangedKey}
                      key={eachInput.keyName}
                    />
                  );
                } else if (eachInput.type === "multiSelect") {
                  return (
                    <RecruitDrawerMultiSelect
                      drawerInput={eachInput}
                      filteredOptions={filteredOptions}
                      onChange={handleInputChange}
                      currentChangedKey={currentChangedKey}
                      key={eachInput.keyName}
                    />
                  );
                } else if (eachInput.type === "checkbox") {
                  return (
                    <FilterDrawerCheckbox
                      drawerInput={eachInput}
                      filteredOptions={filteredOptions}
                      onChange={handleInputChange}
                      key={eachInput.keyName}
                    />
                  );
                } else if (eachInput.type === "text" && filteredOptions['Filtered Columns']) {
                  return (
                    <RecruitDrawerText
                      drawerInput={eachInput}
                      filteredOptions={filteredOptions}
                      onChange={handleInputChange}
                      currentChangedKey={currentChangedKey}
                      key={eachInput.keyName}
                      
                    />
                  );
                }else {
                  return null;
                }
              })}
          </div>
        )}

        <Space wrap className="btnsRight">
          <Button type="link" onClick={doReset} className="linkBtn">
            RESET
          </Button>
          <Button
            type="primary"
            onClick={doApply}
          >
            APPLY
          </Button>
        </Space>
      </Drawer>
    </React.Fragment>
  );
};

export default RecruitmentDrawer;
