import React, { ChangeEvent } from 'react';
import { DatePicker } from 'antd';
import { IRegularizeData, IRegularizeDrawerInputProps } from 'models/interfaces/Regularize/regularizeOptions.model';
import type { Moment } from 'moment';

interface IRegularizeInputDatePicker{
  drawerInput: IRegularizeDrawerInputProps
  handleChange: (value: Moment| null) => void
  RegularisationData: IRegularizeData;
  setRegularisationData: React.Dispatch<React.SetStateAction<IRegularizeData>>
}

const RegularizeDrawerDatePicker:React.FC<IRegularizeInputDatePicker> = ({drawerInput, handleChange, RegularisationData, setRegularisationData}) => {
  return (
    <React.Fragment>
      <div className="inputWrap">
        <label>{drawerInput.label}</label>
        <DatePicker
          disabled
          format={'DD-MM-YYYY'}
          value={RegularisationData.date} 
          onChange={handleChange}
        />
      </div>
   </React.Fragment>
  )
}

export default RegularizeDrawerDatePicker;
