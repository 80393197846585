import React from 'react';
import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { IFilterDrawerInputProps } from 'models/interfaces';

const FilterDrawerCheckbox: React.FC<{
    drawerInput: IFilterDrawerInputProps;
    filteredOptions: any;
    onChange: (filed: string, _value: any) => void;
}> = ({drawerInput, filteredOptions, onChange }) => {

    const handleChange = (e: CheckboxChangeEvent) => {
        onChange(drawerInput.keyName, e.target.checked);
    };

    return (
        <div className="inputWrap">
            <Checkbox checked={filteredOptions[drawerInput.keyName]} onChange={handleChange}>
                {drawerInput.label}
            </Checkbox>
        </div>
    );
};

export default FilterDrawerCheckbox;