import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import {
  IListElement,
  IDateElements,
  ISkyboxUser,
  IRole,
  IBuyerTag,
  IListerTag,
  ITicketbashBroker,
  ISkyboxVendor,
} from "models/interfaces";
import { FilterOptionsService } from "services/filters/filterOptions.service";

let initialState: {
  brokers: IListElement[];
  buyers: IListElement[];
  shippers: IListElement[];
  events: IListElement[];
  venues: IListElement[];
  dateRange: IDateElements[];
  cards: IListElement[];
  alerts: IListElement[];
  currencies: IListElement[];
  reports: IListElement[];
  recipients: IListElement[];
  skyboxUsers: ISkyboxUser[];
  skyboxVendors: ISkyboxVendor[];
  roles: IRole[];
  splitTypes: IListElement[];
  buyerTypes: IBuyerTag[];
  listerTypes: IListerTag[];
  ticketbashBrokers: ITicketbashBroker[];
  saleCategories: IListElement[];
  customerClassifications: IListElement[];
  states: IListElement[];
  inventoryCancellationTypes: IListElement[];
  seatIntelligenceData: IListElement[];
  scrapingStatusData: IListElement[];
  brokerUsers: IListElement[];
  listerUsers: IListElement[];
  buyerUsers: IListElement[];
  seatMapCreatorUsers: IListElement[];
  checkerUsers: IListElement[];
  venueDatePresets: IListElement[];
  stockTypes: IListElement[];
  paymentStatusTypes: IListElement[];
  agingPeriodTypes: IListElement[];
  fulfillmentStatusTypes: IListElement[];
  showCategories: IListElement[];
} = {
  brokers: [],
  buyers: [],
  shippers: [],
  events: [],
  venues: [],
  dateRange: [],
  cards: [],
  alerts: [],
  currencies: [],
  reports: [],
  recipients: [],
  skyboxUsers: [],
  skyboxVendors: [],
  roles: [],
  splitTypes: [],
  buyerTypes: [],
  listerTypes: [],
  ticketbashBrokers: [],
  saleCategories: [],
  customerClassifications: [],
  states: [],
  inventoryCancellationTypes: [],
  seatIntelligenceData: [],
  scrapingStatusData: [],
  brokerUsers: [],
  listerUsers: [],
  buyerUsers: [],
  seatMapCreatorUsers: [],
  checkerUsers: [],
  venueDatePresets: [],
  stockTypes: [],
  paymentStatusTypes: [],
  agingPeriodTypes: [],
  fulfillmentStatusTypes: [],
  showCategories: [],
};

const getShowCategories = createAsyncThunk(
  "filters/getShowCategories",
  async () => {
    const response = await new FilterOptionsService().getShowCategories();
    return response.data.data.show_categories;
  }
);

const getBrokerFilterData = createAsyncThunk(
  "filters/getBrokerFilterData",
  async (current_report_id: number) => {
    const response = await new FilterOptionsService().getBrokerTagsByReportId({
      current_report_id,
    });
    return response.data.data.brokers;
  }
);

const getBuyerFilterData = createAsyncThunk(
  "filters/getBuyerFilterData",
  async () => {
    const response = await new FilterOptionsService().getBuyers();
    return response.data.data.buyers;
  }
);

const getEventFilterData = createAsyncThunk(
  "filters/getEventFilterData",
  async () => {
    const response = await new FilterOptionsService().getEvents();
    return response.data.data.events;
  }
);

const getVenueFilterData = createAsyncThunk(
  "filters/getVenueFilterData",
  async () => {
    const response = await new FilterOptionsService().getVenues();
    return response.data.data.venues;
  }
);

const getDateRangeFilterData = createAsyncThunk(
  "filters/getDateRangeFilterData",
  async () => {
    const response = await new FilterOptionsService().getDateRange();
    return response.data.data.dates;
  }
);

const getCardFilterData = createAsyncThunk(
  "filters/getCardFilterData",
  async () => {
    const response = await new FilterOptionsService().getCards();
    return response.data.data.cards;
  }
);

const getAlertFilterData = createAsyncThunk(
  "filters/getAlertFilterData",
  async () => {
    const response = await new FilterOptionsService().getAlerts();
    return response.data.data.alerts;
  }
);

const getCurrencyFilterData = createAsyncThunk(
  "filters/getCurrencyFilterData",
  async () => {
    const response = await new FilterOptionsService().getCurrencies();
    return response.data.data.currencies;
  }
);

const getReportFilterData = createAsyncThunk(
  "filters/getReportFilterData",
  async (current_report_id: number) => {
    const response = await new FilterOptionsService().getReports({
      current_report_id,
    });
    return response.data.data.reports;
  }
);

const getRecipientFilterData = createAsyncThunk(
  "filters/getRecipientFilterData",
  async () => {
    const response = await new FilterOptionsService().getRecipients();
    return response.data.data.recipients;
  }
);

const getSkyBoxUserData = createAsyncThunk(
  "filters/getSkyBoxUserData",
  async () => {
    const response = await new FilterOptionsService().getSkyBoxUsers();
    return response.data.data.allSkyboxUsers;
  }
);

const getSkyBoxVendorData = createAsyncThunk(
  "filters/getVendorsData",
  async () => {
    const response = await new FilterOptionsService().getSkyBoxVendors();
    return response.data.data.allSkyboxVendors;
  }
);

const getRoleData = createAsyncThunk("filters/getRoleData", async () => {
  const response = await new FilterOptionsService().getRoles();
  return response.data.data.roles;
});

const getSplitTypeData = createAsyncThunk(
  "filters/getSplitTypeData",
  async () => {
    const response = await new FilterOptionsService().getSplitTypes();
    return response.data.data.splitTypes.filter(
      (each) => each.label.toLowerCase() !== "custom"
    );
  }
);

const getBuyerTagData = createAsyncThunk(
  "filters/getBuyerTagData",
  async () => {
    const response = await new FilterOptionsService().getBuyerTypes();
    return response.data.data.buyerTags;
  }
);

const getListerTagData = createAsyncThunk(
  "filters/getListerTagData",
  async () => {
    const response = await new FilterOptionsService().getListerTypes();
    return response.data.data.listerTags;
  }
);

const getTicketbashBrokerData = createAsyncThunk(
  "filters/getTicketbashBrokerData",
  async () => {
    const response = await new FilterOptionsService().getTicketbashBrokerList();
    return response.data.data.ticketbashBrokers;
  }
);

const getSaleCategoryFilterData = createAsyncThunk(
  "filters/getSaleCategoryFilterData",
  async () => {
    const response = await new FilterOptionsService().getSaleCategories();
    return response.data.data.saleCategories;
  }
);
const getCustomerClassificationFilterData = createAsyncThunk(
  "filters/getCustomerClassificationFilterData",
  async () => {
    const response =
      await new FilterOptionsService().getCustomerClassifications();
    return response.data.data.customerClassifications;
  }
);

const getAllStatesData = createAsyncThunk(
  "filters/getAllStatesData",
  async () => {
    const response = await new FilterOptionsService().getStates();
    return response.data.data.states;
  }
);

const getAllInventoryCancellationTypesData = createAsyncThunk(
  "filters/getAllInventoryCancellationTypesData",
  async () => {
    const response = await new FilterOptionsService().getInventoryCancellationTypes();
    return response.data.data.inventoryCancellationTypes;  
  }
)

const getSeatIntelligenceData = createAsyncThunk(
  "filters/getSeatIntelligenceData",
  async () => {
    const response =
      await new FilterOptionsService().getSeatIntelligenceDetails();
    return response.data.data.seatIntelligenceData;
  }
);

const getScrapingStatusData = createAsyncThunk(
  "filters/getScrapingStatusData",
  async () => {
    const response =
      await new FilterOptionsService().getScrapingStatusDetails();
    return response.data.data.scrapingStatusData;
  }
);

const getBrokerUserData = createAsyncThunk(
  "filters/getBrokerUserData",
  async () => {
    const response = await new FilterOptionsService().getBrokerUserList();
    return response.data.data.brokerUserList;
  }
);

const getBuyerUserData = createAsyncThunk(
  "filters/getBuyerUserData",
  async () => {
    const response = await new FilterOptionsService().getBuyerUserList();
    return response.data.data.buyerUserList;
  }
);

const getListerUserData = createAsyncThunk(
  "filters/getListerUserData",
  async () => {
    const response = await new FilterOptionsService().getListerUserList();
    return response.data.data.listerUserList;
  }
);

const getShipperUserData = createAsyncThunk(
  "filters/getShipperUserData",
  async () => {
    const response = await new FilterOptionsService().getShippers();
    return response.data.data.shippers;
  }
);

const getSeatMapCreatorUserData = createAsyncThunk(
  "filters/getSeatMapCreatorUserData",
  async () => {
    const response =
      await new FilterOptionsService().getSeatMapCreatorUserList();
    return response.data.data.seatMapCreatorUserList;
  }
);

const getCheckerUserData = createAsyncThunk(
  "filters/getCheckerUserData",
  async () => {
    const response = await new FilterOptionsService().getCheckerUserList();
    return response.data.data.checkerUserList;
  }
);

const getVenuesDatePresets = createAsyncThunk(
  "filters/getVenuesDatePresets",
  async () => {
    const response = await new FilterOptionsService().getVenuesDatePresets();
    return response.data.data.newVenues;
  }
);

const getPaymentStatusList = createAsyncThunk(
  "filters/getPaymentStatusList",
  async () => {
    const response = await new FilterOptionsService().getPaymentStatusList();
    return response.data.data.paymentStatus;
  }
);

const getAgingPeriodList = createAsyncThunk(
  "filters/getAgingPeriodList",
  async () => {
    const response = await new FilterOptionsService().getAgingPeriodList();
    return response.data.data.agingPeriods;
  }
);

const getFulfillmentStatusList = createAsyncThunk(
  "filters/getFulfillmentStatusList",
  async () => {
    const response =
      await new FilterOptionsService().getFulfillmentStatusList();
    return response.data.data.fulfillmentStatuses;
  }
);

export const filterSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getShowCategories.fulfilled, (state, action) => {
      state.showCategories = action.payload;
    });

    builder.addCase(getBrokerFilterData.fulfilled, (state, action) => {
      state.brokers = action.payload;
    });

    builder.addCase(getBuyerFilterData.fulfilled, (state, action) => {
      state.buyers = action.payload;
    });

    builder.addCase(getShipperUserData.fulfilled, (state, action) => {
      state.shippers = action.payload;
    });

    builder.addCase(getEventFilterData.fulfilled, (state, action) => {
      state.events = action.payload;
    });

    builder.addCase(getVenueFilterData.fulfilled, (state, action) => {
      state.venues = action.payload;
    });

    builder.addCase(getDateRangeFilterData.fulfilled, (state, action) => {
      state.dateRange = action.payload;
    });

    builder.addCase(getCardFilterData.fulfilled, (state, action) => {
      state.cards = action.payload;
    });

    builder.addCase(getAlertFilterData.fulfilled, (state, action) => {
      state.alerts = action.payload;
    });

    builder.addCase(getCurrencyFilterData.fulfilled, (state, action) => {
      state.currencies = action.payload;
    });

    builder.addCase(getReportFilterData.fulfilled, (state, action) => {
      state.reports = action.payload;
    });

    builder.addCase(getRecipientFilterData.fulfilled, (state, action) => {
      state.recipients = action.payload;
    });

    builder.addCase(getSkyBoxUserData.fulfilled, (state, action) => {
      state.skyboxUsers = action.payload;
    });

    builder.addCase(getSkyBoxVendorData.fulfilled, (state, action) => {
      state.skyboxVendors = action.payload;
    });

    builder.addCase(getRoleData.fulfilled, (state, action) => {
      state.roles = action.payload;
    });

    builder.addCase(getSplitTypeData.fulfilled, (state, action) => {
      state.splitTypes = action.payload;
    });

    builder.addCase(getBuyerTagData.fulfilled, (state, action) => {
      state.buyerTypes = action.payload;
    });

    builder.addCase(getListerTagData.fulfilled, (state, action) => {
      state.listerTypes = action.payload;
    });

    builder.addCase(getTicketbashBrokerData.fulfilled, (state, action) => {
      state.ticketbashBrokers = action.payload;
    });

    builder.addCase(getSaleCategoryFilterData.fulfilled, (state, action) => {
      state.saleCategories = action.payload;
    });

    builder.addCase(
      getCustomerClassificationFilterData.fulfilled,
      (state, action) => {
        state.customerClassifications = action.payload;
      }
    );

    builder.addCase(getAllStatesData.fulfilled, (state, action) => {
      state.states = action.payload;
    });

    builder.addCase(getAllInventoryCancellationTypesData.fulfilled, (state, action) => {
      state.inventoryCancellationTypes = action.payload;
    })

    builder.addCase(getSeatIntelligenceData.fulfilled, (state, action) => {
      state.seatIntelligenceData = action.payload;
    });

    builder.addCase(getScrapingStatusData.fulfilled, (state, action) => {
      state.scrapingStatusData = action.payload;
    });

    builder.addCase(getBrokerUserData.fulfilled, (state, action) => {
      state.brokerUsers = action.payload;
    });

    builder.addCase(getListerUserData.fulfilled, (state, action) => {
      state.listerUsers = action.payload;
    });

    builder.addCase(getBuyerUserData.fulfilled, (state, action) => {
      state.buyerUsers = action.payload;
    });

    builder.addCase(getSeatMapCreatorUserData.fulfilled, (state, action) => {
      state.seatMapCreatorUsers = action.payload;
    });

    builder.addCase(getCheckerUserData.fulfilled, (state, action) => {
      state.checkerUsers = action.payload;
    });

    builder.addCase(getVenuesDatePresets.fulfilled, (state, action) => {
      state.venueDatePresets = action.payload;
    });

    builder.addCase(getPaymentStatusList.fulfilled, (state, action) => {
      state.paymentStatusTypes = action.payload;
    });

    builder.addCase(getAgingPeriodList.fulfilled, (state, action) => {
      state.agingPeriodTypes = action.payload;
    });

    builder.addCase(getFulfillmentStatusList.fulfilled, (state, action) => {
      state.fulfillmentStatusTypes = action.payload;
    });
  },
});

const brokers = (state: RootState) => state.filters.brokers;
const buyers = (state: RootState) => state.filters.buyers;
const shippers = (state: RootState) => state.filters.shippers;
const events = (state: RootState) => state.filters.events;
const venues = (state: RootState) => state.filters.venues;
const dateRange = (state: RootState) => state.filters.dateRange;
const cards = (state: RootState) => state.filters.cards;
const alerts = (state: RootState) => state.filters.alerts;
const currencies = (state: RootState) => state.filters.currencies;
const reports = (state: RootState) => state.filters.reports;
const recipients = (state: RootState) => state.filters.recipients;
const skyboxUsers = (state: RootState) => state.filters.skyboxUsers;
const skyboxVendors = (state: RootState) => state.filters.skyboxVendors;
const roles = (state: RootState) => state.filters.roles;
const splitTypes = (state: RootState) => state.filters.splitTypes;
const buyerTags = (state: RootState) => state.filters.buyerTypes;
const listerTags = (state: RootState) => state.filters.listerTypes;
const ticketbashBrokers = (state: RootState) => state.filters.ticketbashBrokers;
const saleCategories = (state: RootState) => state.filters.saleCategories;
const customerClassifications = (state: RootState) =>
  state.filters.customerClassifications;
const states = (state: RootState) => state.filters.states;
const inventoryCancellationTypes = (state: RootState) => state.filters.inventoryCancellationTypes;
const seatIntelligenceData = (state: RootState) =>
  state.filters.seatIntelligenceData;
const scrapingStatusData = (state: RootState) =>
  state.filters.scrapingStatusData;
const brokerUsers = (state: RootState) => state.filters.brokerUsers;
const listerUsers = (state: RootState) => state.filters.listerUsers;
const buyerUsers = (state: RootState) => state.filters.buyerUsers;
const seatMapCreatorUsers = (state: RootState) =>
  state.filters.seatMapCreatorUsers;
const checkerUsers = (state: RootState) => state.filters.checkerUsers;
const venueDatePresets = (state: RootState) => state.filters.venueDatePresets;
const paymentStatusTypes = (state: RootState) =>
  state.filters.paymentStatusTypes;
const agingPeriodTypes = (state: RootState) => state.filters.agingPeriodTypes;
const fulfillmentStatusTypes = (state: RootState) =>
  state.filters.fulfillmentStatusTypes;
const showCategories = (state: RootState) => state.filters.showCategories;

export const actions = {
  getBrokerFilterData,
  getBuyerFilterData,
  getShipperUserData,
  getEventFilterData,
  getVenueFilterData,
  getDateRangeFilterData,
  getCardFilterData,
  getAlertFilterData,
  getCurrencyFilterData,
  getReportFilterData,
  getRecipientFilterData,
  getSkyBoxUserData,
  getSkyBoxVendorData,
  getRoleData,
  getSplitTypeData,
  getBuyerTagData,
  getListerTagData,
  getTicketbashBrokerData,
  getSaleCategoryFilterData,
  getCustomerClassificationFilterData,
  getAllStatesData,
  getAllInventoryCancellationTypesData,
  getSeatIntelligenceData,
  getScrapingStatusData,
  getBrokerUserData,
  getListerUserData,
  getBuyerUserData,
  getVenuesDatePresets,
  getPaymentStatusList,
  getAgingPeriodList,
  getSeatMapCreatorUserData,
  getCheckerUserData,
  getFulfillmentStatusList,
  getShowCategories,
};
export const { reset } = filterSlice.actions;
export {
  brokers,
  buyers,
  shippers,
  events,
  venues,
  dateRange,
  cards,
  alerts,
  currencies,
  reports,
  recipients,
  skyboxUsers,
  skyboxVendors,
  roles,
  splitTypes,
  buyerTags,
  listerTags,
  ticketbashBrokers,
  saleCategories,
  customerClassifications,
  states,
  inventoryCancellationTypes,
  seatIntelligenceData,
  scrapingStatusData,
  brokerUsers,
  listerUsers,
  buyerUsers,
  venueDatePresets,
  paymentStatusTypes,
  agingPeriodTypes,
  seatMapCreatorUsers,
  checkerUsers,
  fulfillmentStatusTypes,
  showCategories,
};
export default filterSlice.reducer;
