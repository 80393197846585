import { useState } from "react";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { initializeApp } from "firebase/app";
import jwtDecode from "jwt-decode";
import { useHistory } from "react-router-dom";
import axiosClient from "sharedServices/api/axios.services";
import { IApiBaseResponse } from "models/interfaces/Iglobal";
import { RoutesEnum } from "models/enums";
import { getSecondsDiff } from "utils/formatTimePeriod";
import { decodeQueryStringData, getEncodedData } from "utils/commonFunction";

const ALLOWED_DOMAINS = ["digitalavenues.com"]; // Replace with your allowed domain names

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_POROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASURE_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const provider = new GoogleAuthProvider();

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);

export function useGoogleSignIn() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const history = useHistory();

  async function handleSignIn() {
    setIsLoading(true);
    provider.setCustomParameters({
      hd: ALLOWED_DOMAINS.join(","),
      prompt: "select_account",
    });
  

    try {
      const result = await signInWithPopup(auth, provider);
      const { user } = result;
      const { email, displayName } = user;

      const domain = email?.split("@")[1] ?? "";
      if (ALLOWED_DOMAINS.includes(domain)) {
        //Get JWT here
        const rep: any = await getAuthToken(email ?? "", displayName ?? "");

        if (rep.data.status_code === 401) {
          auth.signOut();
          setIsLoading(false);
          const msg = getEncodedData("You are not authorized");
          return history.push(`/login?msg=${msg}`);
        }

        const idToken: string = rep.data.access_token;
        if (idToken) {
          try {
            const decodedAuth: any = jwtDecode(idToken);
            let expirationTime = decodedAuth.exp;
            window.localStorage.setItem("emailId", email ?? "");
            window.localStorage.setItem("displayName", displayName ?? "");
            window.localStorage.setItem("expirationTime", expirationTime);
            window.localStorage.setItem("authToken", idToken);
            window.localStorage.setItem("employee_code", decodedAuth.sub.biometric_emp_code);
          } catch (error) {
            setIsLoading(false);
            console.error(error);
          }
        }
        setIsLoading(false);

        let redirectTo = new URLSearchParams(window.location.search).get('redirectTo') || '/';

        if(redirectTo !== '/')
          redirectTo = decodeQueryStringData(redirectTo);

        history.push(redirectTo)
      } else {
        auth.signOut();
        setIsLoading(false);
        const msg = getEncodedData(
          `Sorry, only ${ALLOWED_DOMAINS.join(" or ")} domain is allowed.`
        );
        return history.push(`/login?msg=${msg}`);
      }
    } catch (error) {
      // Handle errors here
      setIsLoading(false);
      console.error(error);
    }
  }


  function handleSignOut() {
    auth.signOut();
    window.localStorage.removeItem("emailId");
    window.localStorage.removeItem("displayName");
    window.localStorage.removeItem("expirationTime");
    window.localStorage.removeItem("authToken");
    window.localStorage.removeItem("employee_code");
    window.localStorage.removeItem("redirectUrl");
    history.push("/login");
  }

  return { isLoading, handleSignIn, handleSignOut };
}

export function useTokenID() {
  let userInfo: any = {};
  try {
    const token = window.localStorage.getItem("authToken");
    if (token) {
      userInfo = jwtDecode(token);
      if (userInfo) {
        return userInfo;
      }
    }
    throw Error;
  } catch (error) {
    auth.signOut();
    window.localStorage.removeItem("emailId");
    window.localStorage.removeItem("displayName");
    window.localStorage.removeItem("expirationTime");
    window.localStorage.removeItem("authToken");
    window.localStorage.removeItem("employee_code");
  }
}

export function verifyTokenID() {


  // eslint-disable-next-line react-hooks/rules-of-hooks
  const userInfo = useTokenID() //NOSONAR

  const timeout = userInfo?.exp || window.localStorage.getItem("expirationTime");

  if (!timeout) return false;

  const diff = getSecondsDiff(
    parseInt(timeout),
    Math.floor(Date.now() / 1000)
  ) as number;

  if (timeout && diff < 0) {
    auth.signOut();
    window.localStorage.removeItem("emailId");
    window.localStorage.removeItem("displayName");
    window.localStorage.removeItem("expirationTime");
    window.localStorage.removeItem("authToken");
    window.localStorage.removeItem("employee_code");

    return false;
  }
  return true;
}

export async function getAuthToken(email: string, name: string) {
  return axiosClient.post<IApiBaseResponse<any>>(
    RoutesEnum.GENERATE_AUTHTOKEN,
    {
      email_id: email,
      display_name: name,
    }
  );
}
