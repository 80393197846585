import { RoutesEnum } from "models/enums";
import {
  IBrokerList,
  IBuyerList,
  IEventList,
  IVenueList,
  IDateList,
  ICardList,
  IAlertList,
  ICurrencyList,
  IReportList,
  IRecipientList,
  ISkyboxUserList,
  IRoleList,
  ISplitTypeList,
  IBuyerTagList,
  IListerTagList,
  ITicketbashBrokerList,
  ISaleCategoryList,
  ICustomerClassificationList,
  IStateList,
  ISeatIntelligenceList,
  IScrapingStatusList,
  IListerUserList,
  IBrokerUserList,
  INewVenueList,
  IPaymentStatusesList,
  IAgingPeriodsList,
  ISeatMapCreatorUserList,
  ICheckerUserList,
  IFulfillmentStatusesList,
  IBuyerUserList,
  IShipperList,
  ISkyboxVendorList,
  IInventoryCancellationTypesList,
} from "models/interfaces";
import { IApiBaseResponse } from "models/interfaces/Iglobal";
import axiosClient from "sharedServices/api/axios.services";

class FilterOptionsService {
  public getBrokerTagsByReportId = async (queryParams: {
    current_report_id: number;
  }) => {
    return axiosClient.get<IApiBaseResponse<IBrokerList>>(
      RoutesEnum.BROKER_TAGS_BY_REPORT_ID,
      { params: { ...queryParams } }
    );
  };

  public getBuyers = async () => {
    return axiosClient.get<IApiBaseResponse<IBuyerList>>(RoutesEnum.BUYER_LIST);
  };

  public getShippers = async () => {
    return axiosClient.get<IApiBaseResponse<IShipperList>>(
      RoutesEnum.SHIPPER_LIST
    );
  };

  public getEvents = async () => {
    return axiosClient.get<IApiBaseResponse<IEventList>>(RoutesEnum.EVENT_LIST);
  };

  public getVenues = async () => {
    return axiosClient.get<IApiBaseResponse<IVenueList>>(RoutesEnum.VENUE_LIST);
  };

  public getDateRange = async () => {
    return axiosClient.get<IApiBaseResponse<IDateList>>(RoutesEnum.DATE_RANGE);
  };

  public getCards = async () => {
    return axiosClient.get<IApiBaseResponse<ICardList>>(RoutesEnum.CARD_LIST);
  };

  public getAlerts = async () => {
    return axiosClient.get<IApiBaseResponse<IAlertList>>(RoutesEnum.ALERT_LIST);
  };

  public getCurrencies = async () => {
    return axiosClient.get<IApiBaseResponse<ICurrencyList>>(
      RoutesEnum.CURRENCY_LIST
    );
  };

  //NEW
  public getReports = async (queryParams: { current_report_id: number }) => {
    return axiosClient.get<IApiBaseResponse<IReportList>>(
      RoutesEnum.REPORT_LIST,
      { params: { ...queryParams } }
    );
  };

  public getRecipients = async () => {
    return axiosClient.get<IApiBaseResponse<IRecipientList>>(
      RoutesEnum.RECIPIENT_LIST
    );
  };

  public getSkyBoxUsers = async () => {
    return axiosClient.get<IApiBaseResponse<ISkyboxUserList>>(
      RoutesEnum.SKYBOX_USER_LIST
    );
  };

  public getSkyBoxVendors = async () => {
    return axiosClient.get<IApiBaseResponse<ISkyboxVendorList>>(
      RoutesEnum.SKYBOX_VENDORS
    );
  };

  public getRoles = async () => {
    return axiosClient.get<IApiBaseResponse<IRoleList>>(RoutesEnum.ROLE_LIST);
  };

  public getSplitTypes = async () => {
    return axiosClient.get<IApiBaseResponse<ISplitTypeList>>(
      RoutesEnum.SPLIT_TYPE_LIST
    );
  };

  public getBuyerTypes = async () => {
    return axiosClient.get<IApiBaseResponse<IBuyerTagList>>(
      RoutesEnum.BUYER_TAG_LIST
    );
  };

  public getListerTypes = async () => {
    return axiosClient.get<IApiBaseResponse<IListerTagList>>(
      RoutesEnum.LISTER_TAG_LIST
    );
  };

  public getTicketbashBrokerList = async () => {
    return axiosClient.get<IApiBaseResponse<ITicketbashBrokerList>>(
      RoutesEnum.TICKETBASH_BROKER_LIST
    );
  };

  public getSaleCategories = async () => {
    return axiosClient.get<IApiBaseResponse<ISaleCategoryList>>(
      RoutesEnum.SALE_CATEGORY_LIST
    );
  };

  public getCustomerClassifications = async () => {
    return axiosClient.get<IApiBaseResponse<ICustomerClassificationList>>(
      RoutesEnum.CUSTOMER_CLASSIFICATION_LIST
    );
  };

  public getStates = async () => {
    return axiosClient.get<IApiBaseResponse<IStateList>>(RoutesEnum.STATE_LIST);
  };

  public getInventoryCancellationTypes = async () => {
    return axiosClient.get<IApiBaseResponse<IInventoryCancellationTypesList>>(RoutesEnum.INVENTORY_CANCELLATION_TYPE_LIST);
  };

  public getSeatIntelligenceDetails = async () => {
    return axiosClient.get<IApiBaseResponse<ISeatIntelligenceList>>(
      RoutesEnum.SEAT_INTELLIGENCE_DETAILS
    );
  };

  public getScrapingStatusDetails = async () => {
    return axiosClient.get<IApiBaseResponse<IScrapingStatusList>>(
      RoutesEnum.SCRAPING_STATUS_DETAILS
    );
  };

  public getBrokerUserList = async () => {
    return axiosClient.get<IApiBaseResponse<IBrokerUserList>>(
      RoutesEnum.BROKER_USER_LIST
    );
  };

  public getBuyerUserList = async () => {
    return axiosClient.get<IApiBaseResponse<IBuyerUserList>>(
      RoutesEnum.BUYER_USER_LIST
    );
  };

  public getListerUserList = async () => {
    return axiosClient.get<IApiBaseResponse<IListerUserList>>(
      RoutesEnum.LISTER_USER_LIST
    );
  };

  public getSeatMapCreatorUserList = async () => {
    return axiosClient.get<IApiBaseResponse<ISeatMapCreatorUserList>>(
      RoutesEnum.SEAT_MAP_CREATOR_USER_LIST
    );
  };

  public getCheckerUserList = async () => {
    return axiosClient.get<IApiBaseResponse<ICheckerUserList>>(
      RoutesEnum.CHECKER_USER_LIST
    );
  };

  public getVenuesDatePresets = async () => {
    return axiosClient.get<IApiBaseResponse<INewVenueList>>(
      RoutesEnum.VENUE_DATE_PRESETS
    );
  };

  public getPaymentStatusList = async () => {
    return axiosClient.get<IApiBaseResponse<IPaymentStatusesList>>(
      RoutesEnum.ZONE_PAYMENT_STATUS_LIST
    );
  };

  public getAgingPeriodList = async () => {
    return axiosClient.get<IApiBaseResponse<IAgingPeriodsList>>(
      RoutesEnum.AGING_PERIODS_LIST
    );
  };

  public getFulfillmentStatusList = async () => {
    return axiosClient.get<IApiBaseResponse<IFulfillmentStatusesList>>(
      RoutesEnum.FULFILLMENT_STATUSES_LIST
    );
  };

  public getShowCategories = async () => {
    return axiosClient.get<IApiBaseResponse>(RoutesEnum.SHOW_CATEGORIES);
  };
}

export { FilterOptionsService };
