import { RoutesEnum } from "models/enums";
import { IApiBaseResponse } from "models/interfaces/Iglobal";
import axiosClient from "sharedServices/api/axios.services";

export class LeaveReconcilliationService {

  public getLeaveData = async (params: {}) => {
    return axiosClient.get<IApiBaseResponse<any>>(
      RoutesEnum.GET_LEAVE_DATA,
      {
        params: params
      }
    );
  };

  public getLeaveReconcilliationMasterSheetData = async (dateDto: {}) => {
    return axiosClient.get<IApiBaseResponse<any>>(
      RoutesEnum.GET_LEAVE_RECONCILLIATION_MASTER_SHEET,
    );
  };

  public updateRegularizationRequest = async (dateDto: {}) => {
    return axiosClient.post<IApiBaseResponse<any>>(
      RoutesEnum.UPDATE_REGULARIZATION_STATUS,
      {
        ...dateDto,
      }
    );
  };


  public saveNewDataToMasterTable = async (data: {}) => {
    return axiosClient.post<IApiBaseResponse<any>>(
      RoutesEnum.LEAVE_RECONCILLIATION_MASTER_DATA_FORM, data
    );
  };

  public getEmailExistance = async (emailDto: {}) => {
    return axiosClient.get<IApiBaseResponse<any>>(
      RoutesEnum.CHECK_EMAIL_ID_IN_LEAVE_RECONCILLIATION_MASTER_SHEET,
      {
        params: emailDto,
      }
    );
  };


  public getEmployeeIDExistance = async (employeeIDDto: {}) => {
    return axiosClient.get<IApiBaseResponse<any>>(
      RoutesEnum.LEAVE_RECONCILLIATION_EMPLOYEE_ID_CHECK,
      {
        params: employeeIDDto,
      }
    );
  };


  public getAllDesignations = async () => {
    return axiosClient.get<IApiBaseResponse<any>>(
      RoutesEnum.GET_ALL_DESIGNATIONS
    );
  };


  public createNewColumn = async (data: {}) => {
    return axiosClient.get<IApiBaseResponse<any>>(
      RoutesEnum.CREATE_CURRENT_FINANCIAL_YEAR_COLUMN,
      {
        params: data
      }
    );
  };

  public postLeaveData = async (uploadBiometricData: {}, date_for?: {}) => {
    return axiosClient.post<IApiBaseResponse<any>>(
      RoutesEnum.UPLOAD_LEAVE_DOCUMENT,
      uploadBiometricData,
      // {
      //   params: date_for,
      // }
    );
  };
  
}