


import React, { useEffect, useState } from 'react';
import { InputNumber } from 'antd';
import { IFilterDrawerInputProps } from 'models/interfaces';

interface RecruitDrawerNumberProps {
    drawerInput: IFilterDrawerInputProps;
    filteredOptions: { [key: string]: any };
    onChange: (field: string, value: number | null) => void;
    currentChangedKey: string | null;
}

const RecruitDrawerText: React.FC<RecruitDrawerNumberProps> = ({ drawerInput, filteredOptions,currentChangedKey, onChange }) => {
    
    const handleChange = (value: any) => {
        
        onChange(drawerInput.keyName, value);
    };

    useEffect(() => {
        if (currentChangedKey && currentChangedKey === drawerInput.cascadeKeyName) {
            
          onChange(drawerInput.keyName, 0);
          
        }
      }, [currentChangedKey]);

    return (
        <div className="inputWrap">
            <label>{drawerInput.label}</label>
            <InputNumber
                value={filteredOptions[drawerInput.keyName]?filteredOptions[drawerInput.keyName]: 0}
                onChange={handleChange}
                style={{ width: '100%' }} // Adjust width as needed
                placeholder=  {`Enter ${'Filtered Columns' in filteredOptions ? filteredOptions['Filtered Columns'] : ''} days`} 
                // disabled = {'Filtered Columns' in filteredOptions ? false: true}
                type='number'
                min={0}
            />
        </div>
    );
};

export default RecruitDrawerText;
