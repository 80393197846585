import { IApiBaseResponse } from "models/interfaces/Iglobal";
import axiosClient from "sharedServices/api/axios.services";
import { RoutesEnum } from "models/enums";
import {DurationDateRange, TotalHoursInfo} from "models/interfaces";

export class BiometricTotalHoursData {
    public getBiometricTotalHoursData = async (params: DurationDateRange['data']) => {
      return axiosClient.get<IApiBaseResponse<any>>(
        RoutesEnum.GET_TOTAL_HOURS_DATA,
        {
          params: params,
        }
    );
    }
};