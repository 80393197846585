export const ConstantMsg = {

    LOADING_RESEND_UPLOAD_ONBOARDING_DOCUMENT_EMAIL: {
        content: "Re-sending email to the joiner...",
        duration: 0,
        key: 'RESEND_UPLOAD_ONBOARDING_DOCUMENT_EMAIL',
        className: "toastMsg loadingMsg",
    },
    SUCCESS_RESEND_UPLOAD_ONBOARDING_DOCUMENT_EMAIL: {
      content: "Re-send upload document email to the joiner Successfully",
      key: 'RESEND_UPLOAD_ONBOARDING_DOCUMENT_EMAIL',
      duration: 5,
      className: "toastMsg savedSuccess",
    },
    ERROR_RESEND_UPLOAD_ONBOARDING_DOCUMENT_EMAIL: {
        content: "Failed re-send the email",
        key: 'RESEND_UPLOAD_ONBOARDING_DOCUMENT_EMAIL',
        duration: 5,
        className: "toastMsg savedError",
    },


    LOADING_UPLOAD_ONBOARDING_DOCUMENTS: {
        content: "Uploading your documents...",
        duration: 0,
        key: 'UPLOAD_ONBOARDING_DOCUMENTS',
        className: "toastMsg loadingMsg",
    },
    SUCCESS_UPLOAD_ONBOARDING_DOCUMENTS: {
      content: "Successfully uploaded your documents",
      key: 'UPLOAD_ONBOARDING_DOCUMENTS',
      duration: 5,
      className: "toastMsg savedSuccess",
    },
    ERROR_UPLOAD_ONBOARDING_DOCUMENTS: {
        content: "Failed to upload documents",
        key: 'UPLOAD_ONBOARDING_DOCUMENTS',
        duration: 5,
        className: "toastMsg savedError",
    },

    
    LOADING_DELETE_ONBOARDING_DOCUMENTS: {
        content: "Deleteing your document..",
        duration: 0,
        key: 'DELETE_ONBOARDING_DOCUMENTS',
        className: "toastMsg loadingMsg",
    },
    SUCCESS_DELETE_ONBOARDING_DOCUMENTS: {
      content: "Successfully deleted your documents",
      key: 'DELETE_ONBOARDING_DOCUMENTS',
      duration: 5,
      className: "toastMsg savedSuccess",
    },
    ERROR_DELETE_ONBOARDING_DOCUMENTS: {
        content: "Failed to delete documents",
        key: 'DELETE_ONBOARDING_DOCUMENTS',
        duration: 5,
        className: "toastMsg savedError",
    },


    LOADING_GET_TEAM_MEMBERS: {
        content: "Getting team members data...",
        duration: 0,
        key: 'UPLOAD_ONBOARDING_DOCUMENTS',
        className: "toastMsg loadingMsg",
    },
    SUCCESS_GET_TEAM_MEMBERS: {
      content: "Team members data retrived successfully!",
      key: 'getTeammembersLoadingKey',
      duration: 5,
      className: "toastMsg savedSuccess",
    },
    ERROR_GET_TEAM_MEMBERS: {
        content: "Failed while getting team members",
        key: 'getTeammembersLoadingKey',
        duration: 5,
        className: "toastMsg savedError",
    },


    LOADING_UPLOAD_BIOMETRIC_ATTENDANCE_DOCUMENT: {
        content: "Uploading CSV File",
        duration: 0,
        key: 'sendingCsvDocument',
        className: "toastMsg loadingMsg",
    },
    SUCCESS_UPLOAD_BIOMETRIC_ATTENDANCE_DOCUMENT: {
      content: "Successfully Uploaded the CSV File!",
      key: 'getTeammembersLoadingKey',
      duration: 5,
      className: "toastMsg savedSuccess",
    },
    ERROR_UPLOAD_BIOMETRIC_ATTENDANCE_DOCUMENT: {
        content: "Failed to CUpload the CSV File",
        key: 'getTeammembersLoadingKey',
        duration: 5,
        className: "toastMsg savedError",
    },

    LOADING_CREATE_REGULARIZATION_REQUEST: {
        content: "Creating Your Regularization Request...",
        duration: 0,
        key: 'getTeammembersLoadingKey',
        className: "toastMsg loadingMsg",
    },
    SUCCESS_CREATE_REGULARIZATION_REQUEST: {
      content: "Successfully Created Your Regularization Request!",
      key: 'getTeammembersLoadingKey',
      duration: 5,
      className: "toastMsg savedSuccess",
    },
    ERROR_CREATE_REGULARIZATION_REQUEST: {
        content: "Failed to Create Your Regularization Request",
        key: 'getTeammembersLoadingKey',
        duration: 5,
        className: "toastMsg savedError",
    },



    LOADING_UPDATE_REGULARIZATION_REQUEST_STATUS: {
        content: "Updating regularization status",
        duration: 0,
        key: 'sendingRegularisationRequest',
        className: "toastMsg loadingMsg",
    },
    SUCCESS_UPDATE_REGULARIZATION_REQUEST_STATUS: {
        content: "Regularization status updated successfully",
        duration: 5,
        key: 'RegularisationRequestSend',
        className: "toastMsg savedSuccess"
    },
    ERROR_UPDATE_REGULARIZATION_REQUEST_STATUS: {
        content: "Failed to update regularization status",
        duration: 5,
        key: 'sendingRegularisationRequest',
        className: "toastMsg savedError",
    },


    LOADING_REGULARIZATION_REQUEST: {
        content: "Sending Regularization request",
        duration: 0,
        key: 'sendingRegularisationRequest',
        className: "toastMsg loadingMsg",
    },
    SUCCESS_REGULARIZATION_REQUEST: {
        content: "Regularization request send successfully",
        duration: 5,
        key: 'RegularisationRequestSend',
        className: "toastMsg savedSuccess"
    },
    ERROR_REGULARIZATION_REQUEST: {
        content: "Error occurred during submit reqularization request",
        duration: 5,
        key: 'sendingRegularisationRequest',
        className: "toastMsg savedError",
    },

    
    LOADING_WFH_REQUEST: {
        content: "Sending Work From Home request",
        duration: 0,
        key: 'sendingWfhApplyRequest',
        className: "toastMsg loadingMsg",
    },
    SUCCESS_WFH_REQUEST: {
        content: "Work From Home request send successfully",
        duration: 5,
        key: 'WfhApplyRequestSend',
        className: "toastMsg savedSuccess"
    },
    ERROR_WFH_REQUEST: {
        content: "Error occurred during submit Work From Home request",
        duration: 5,
        key: 'errorInWfhApplyRequest',
        className: "toastMsg savedError",
    },

    LOADING_DELETE_WFH_REQUEST_DATA: {
        content: "Deleting Work From Home request",
        duration: 0,
        key: 'sendingWfhApplyRequest',
        className: "toastMsg loadingMsg",
    },
    SUCCESS_DELETE_WFH_REQUEST_DATA: {
        content: "Work From Home request deleted successfully",
        duration: 5,
        key: 'WfhApplyRequestSend',
        className: "toastMsg loadingMsg"
    },
    ERROR_DELETE_WFH_REQUEST_DATAT: {
        content: "Error occurred during deleting Work From Home request",
        duration: 5,
        key: 'errorInWfhApplyRequest',
        className: "toastMsg savedError",
    },


    LOADING_UPDATE_WFH_REQUEST_STATUS: {
        content: "Updating work from home status",
        duration: 0,
        key: 'updatingWfhApplyStatus',
        className: "toastMsg loadingMsg",
    },
    SUCCESS_UPDATE_WFH_REQUEST_STATUS: {
        content: "WFH status updated successfully",
        duration: 5,
        key: 'WfhApplyStatusUpdated',
        className: "toastMsg savedSuccess"
    },
    ERROR_UPDATE_WFH_REQUEST_STATUS: {
        content: "Failed to update WFH status",
        duration: 5,
        key: 'wrrorInUpdatingWfhApplyStatus',
        className: "toastMsg savedError",
    },


    LOADING_CREATE_WFH_REQUEST_DOCUMENT_UPLOAD: {
        content: "Uploading wfh appliction supporting document....",
        duration: 0,
        key: 'uploadingWfhSupportingDocument',
        className: "toastMsg loadingMsg",
    },
    SUCCESS_CREATE_WFH_REQUEST_DOCUMENT_UPLOAD: {
        content: "Successfully uploaded the document",
        duration: 5,
        key: 'uploadingWfhSupportingDocument',
        className: "toastMsg savedSuccess"
    },
    ERROR_CREATE_WFH_REQUEST_DOCUMENT_UPLOAD: {
        content: "Failed to cupload the document",
        duration: 5,
        key: 'uploadingWfhSupportingDocument',
        className: "toastMsg savedError",
    },


    LOADING_CREATE_WFH_REQUEST_DATA_UPLOAD: {
        content: "Creating the wfh request....",
        duration: 0,
        key: 'uploadingWfhApplicationData',
        className: "toastMsg loadingMsg",
    },
    SUCCESS_CREATE_WFH_REQUEST_DATA_UPLOAD: {
        content: "Successfully created the wfh request",
        duration: 5,
        key: 'uploadingWfhApplicationData',
        className: "toastMsg savedSuccess"
    },
    ERROR_CREATE_WFH_REQUEST_DATA_UPLOAD: {
        content: "Failed to create the wfh request",
        duration: 5,
        key: 'uploadingWfhApplicationData',
        className: "toastMsg savedError",
    }
}
