import React, { useState } from 'react'
import LoadingSpinner from 'utils/sharedComponents/LoadingSpinner'
import moment from 'moment'
import EditableCell from './EditableCell'
import useScrollEffect from 'hooks/useScrollEffect'
import { DeleteOutlined } from '@ant-design/icons'
import NoDataFound from 'views/NoDataFound'


const tableDataStyle = {
  update: {
    minWidth: '86px',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    left: 0
  },
  employee_id: {
    minWidth: '160px',
    textAlign: 'center',
    left: '86px'
  },
  employee_name: {
    minWidth: '220px',
    textAlign: 'left',
    left: '246px'
  },
  designation: {
    minWidth: '230px',
    textAlign: 'left',
    left: '466px'
  },
  email: {
    minWidth: '320px',
    textAlign: 'left',
    left: '696px',
    maxWidth: '200px'
  },
  delete: {
    minWidth: '120px',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}

interface ISort { keyToSort: string, direction: string }

const LeaveReconcilliationMasterTable: React.FC<{
  isTableLoading: boolean,
  tableHeaders: any[],
  tableData: any[],
  handleClickDropdownUpdate: (emp_master_leave_data: any) => void,
  handleClickDelete: (emp_master_leave_data: any) => void
  handleClickInlineUpdate: (emp_master_leave_data: any) => void
  addUpdateOrDeleteLeaveDataInMasterTable: (e: any, formData?: any) => Promise<void>
  setTableData: React.Dispatch<React.SetStateAction<any[]>>
  form: any
  getFormDefault: any
  setForm: React.Dispatch<any>
}> =
  ({ isTableLoading, tableHeaders, tableData, handleClickDropdownUpdate, handleClickDelete, handleClickInlineUpdate, addUpdateOrDeleteLeaveDataInMasterTable, form, getFormDefault, setForm }) => {

    const tableScrollableElement = useScrollEffect()
    const [sort, setSort] = useState<ISort>({ keyToSort: "employee_name", direction: "asc" });

    
    function handleSortClick(header: string) {
      setSort({
        keyToSort: header,
        direction:
          header === sort.keyToSort ? sort.direction === 'asc' ? 'desc' : 'asc' : 'desc'
      })
    }


    function getSortArray(arrayToSort: any[], sort: ISort) {
      if (sort.direction === 'asc') {
        return arrayToSort?.sort((a: any, b: any) => a[sort.keyToSort].toLowerCase().trim().localeCompare(b[sort.keyToSort].toLowerCase().trim()));
      }
      return arrayToSort?.sort((a: any, b: any) => b[sort.keyToSort].toLowerCase().trim().localeCompare(a[sort.keyToSort].toLowerCase().trim()));
    }


    const getIconClass = (column: string) => {
      if (sort.keyToSort === column) {
        return sort.direction === 'asc' ? 'da iconSort icon-sort-asc' : 'da iconSort icon-sort-desc';
      }
      return 'da iconSort icon-sort-asc';
    }
    

    return (
      <LoadingSpinner isLoading={isTableLoading}>
        <div className="customTbl userManagementTbl scroll-y tblStickyTHead timeDelayReport"
          ref={tableScrollableElement}>
          <table className="main-table">
            <thead>
              <tr>
                {
                  tableHeaders.map((curr_header, index) => {

                    if (curr_header.toLowerCase() === 'update' || curr_header.toLowerCase() === 'delete') {
                      return (
                        <th
                          className={`${index <= 4 ? 'colLeftSticky' : ''}`}
                          style={{ ...tableDataStyle[curr_header.toLowerCase()], display: 'table-cell', }}
                          key={curr_header}>
                        </th>
                      )
                    }

                    return (
                      <th
                        className={`${curr_header.includes('opening_balance') ? 'text-right' : 'text-left'} ${index <= 4 ? 'colLeftSticky' : ''} ${curr_header === 'email' ? 'colLeftStickyLast' : ''}`}
                        style={{ ...tableDataStyle[curr_header.toLowerCase()], }}
                        key={curr_header}>
                        {curr_header.split('_').join(' ')}
                        {
                          (curr_header === 'employee_name' || curr_header === 'designation')
                          &&
                          (
                            <span style={{ 'marginLeft': '5px' }}
                              onClick={() => handleSortClick(curr_header)}
                              className={getIconClass(curr_header === 'employee_name' ? 'employee_name' : curr_header)}
                            >
                            </span>
                          )
                        }
                      </th>
                    )
                  })
                }
              </tr>
            </thead>
            <tbody>
              {
                tableData.length > 0 &&
                getSortArray(tableData, sort)?.map((emp_master_leave_data: any, key: number) => {

                  return (
                    <tr key={key}>
                      {
                        tableHeaders.map((curr_header, index) => {

                          <td
                            className={`text-left ${index <= 4 ? 'colLeftSticky' : ''} ${curr_header === 'email' ? 'colLeftStickyLast' : ''}`}
                            style={{ ...tableDataStyle[curr_header.toLowerCase()], }}
                            key={curr_header}>
                          </td>

                          if (curr_header.toLowerCase() === `opening_balance_${moment().year() + 1}`) {
                            return (
                              <EditableCell
                                key={index}
                                getFormDefault={getFormDefault}
                                tableDataStyle={tableDataStyle}
                                form={form}
                                curr_header={curr_header}
                                employee_data={emp_master_leave_data}
                                handleClickInlineUpdate={handleClickInlineUpdate}
                                addUpdateOrDeleteLeaveDataInMasterTable={addUpdateOrDeleteLeaveDataInMasterTable}
                                setForm={setForm}
                              />
                            )
                          }
                          if(curr_header.toLowerCase() === 'update'){
                            return (<td
                                className={`${curr_header.includes('opening_balance') ? 'text-right' : 'text-left'} ${index <= 4 ? 'colLeftSticky' : ''} ${curr_header === 'email' ? 'colLeftStickyLast' : ''}`}
                                style={{ width: `${curr_header.includes('opening_balance') ? '200px' : ''}`, ...tableDataStyle[curr_header.toLowerCase()], }}
                                key={index}
                              >
                                <i className="da icon-pencil-o-dark text-center pointer"  onClick={() => handleClickDropdownUpdate(emp_master_leave_data)}></i>
                              </td>)
                          }
                          if(curr_header.toLowerCase() === 'delete'){
                            return (<td
                                className={`${curr_header.includes('opening_balance') ? 'text-right' : 'text-left'} ${index <= 4 ? 'colLeftSticky' : ''} ${curr_header === 'email' ? 'colLeftStickyLast' : ''}`}
                                style={{ width: `${curr_header.includes('opening_balance') ? '200px' : ''}`, ...tableDataStyle[curr_header.toLowerCase()], }}
                                key={index}
                              >
                                <DeleteOutlined style={{ color: 'red' }} className='text-center pointer' onClick={() => handleClickDelete(emp_master_leave_data)}/>
                              </td>)
                          }

                          return (
                            <td
                              className={`${curr_header.includes('opening_balance') ? 'text-right' : 'text-left'} ${index <= 4 ? 'colLeftSticky' : ''} ${curr_header === 'email' ? 'colLeftStickyLast' : ''}`}
                              style={{ width: `${curr_header.includes('opening_balance') ? '200px' : ''}`, ...tableDataStyle[curr_header.toLowerCase()], }}
                              key={index}
                            >
                              {
                                (
                                  emp_master_leave_data[curr_header.toLowerCase()] === undefined ||
                                  emp_master_leave_data[curr_header.toLowerCase()] === null
                                ) ?
                                  '-'
                                  :
                                  emp_master_leave_data[curr_header.toLowerCase()]
                              }
                            </td>
                          )
                        })
                      }
                    </tr>
                  )
                })
              }
              {(!tableData || tableData.length === 0) && <NoDataFound />}
            </tbody>

            <tfoot></tfoot>
          </table>
        </div>
      </LoadingSpinner>
    )
  }

export default LeaveReconcilliationMasterTable
