import React from 'react'
import { DatePicker, Select } from 'antd';
import type { Moment } from 'moment';
import { IRegularizeData, IRegularizeDrawerInputProps } from 'models/interfaces/Regularize/regularizeOptions.model';

interface IRegularizeInputSingleSelect{
  drawerInput: IRegularizeDrawerInputProps
  handleChange: (value: any, options: any) => void
  RegularisationData: IRegularizeData;
  setRegularisationData: React.Dispatch<React.SetStateAction<IRegularizeData>>
}




const RegularizeDrawerSingleSelect:React.FC<IRegularizeInputSingleSelect> = 
({drawerInput, handleChange, RegularisationData, setRegularisationData}) => {
  return (
    <React.Fragment>
      <div className="inputWrap">
        <label>{drawerInput?.label || "Reason: "}</label>
        <Select
          options={drawerInput.options}
          placeholder="Please select"
          showSearch={true} 
          value={RegularisationData.reason}
          onChange={handleChange}
        />
      </div>
   </React.Fragment>
  )
}

export default RegularizeDrawerSingleSelect;
