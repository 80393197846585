import { useGoogleSignIn } from "utils/auth/google.security";
import PageLoader from "utils/sharedComponents/PageLoader";
import LoginLogo from "assets/images/logo-full.svg";
import LoginBg from "assets/images/login-img.png";
import { decodeQueryStringData } from "utils/commonFunction";
import { usePageTitle } from "hooks";
const GoogleSignIn = () => {
  const { isLoading, handleSignIn } = useGoogleSignIn();

  usePageTitle("DA Housekeeping");
  
  if( isLoading ) {
    return <PageLoader/>
  }

  const searchParams = new URLSearchParams(window.location.search);


  const msg = searchParams.get('msg')

  return (
    <div>
      <div className="loginContainer">
        <div className="leftCont">
        <img
                src={LoginBg}
                alt="DA Housekeeping"
                title="DA Housekeeping"
              />
        </div>

        <div className="rightCont">
          <div className="loginFormWrap">
            <div className="logo-login">
              <img
                src={LoginLogo}
                alt="DA Housekeeping"
                title="DA Housekeeping"
              />
            </div>
            <h3>Welcome Back!</h3>
            <h4 style={{color: 'red', padding: 0, margin: 0, textAlign: 'center'}}>{msg ? decodeQueryStringData(msg):''}</h4>
            <div className="inputWrap btnWrap">
              <button
                type="button"
                className="btnStyle1"
                onClick={handleSignIn}
              >
                Sign in to Nexus
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoogleSignIn;
