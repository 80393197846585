import { TimePicker } from 'antd';
import { IRegularizeData, IRegularizeDrawerInputProps } from 'models/interfaces/Regularize/regularizeOptions.model';
import React from 'react';
import type { Moment } from 'moment';


interface IRegularizeInputTimePicker{
  drawerInput: IRegularizeDrawerInputProps
  handleChange: (value: Moment| null) => void
  RegularisationData: IRegularizeData;
  setRegularisationData: React.Dispatch<React.SetStateAction<IRegularizeData>>
  inTimeAfterOutTime: {bool: boolean, from: string, msg: string}
}

const RegularizeDrawerTimePicker:React.FC<IRegularizeInputTimePicker> = 
({drawerInput, handleChange, RegularisationData, setRegularisationData, inTimeAfterOutTime}) => {
  return (
    <React.Fragment>
      <div className="inputWrap">
        <label>{drawerInput?.label}</label>
        <TimePicker
          value={drawerInput.keyName === 'checkin_time'? RegularisationData.inTime : RegularisationData.outTime}
          format={'hh:mm a'}
          onChange={handleChange} 
        />
        <label className="validdationWrap error">
        {
          inTimeAfterOutTime.msg
        }
        </label>
      </div>
   </React.Fragment>
  )
}

export default RegularizeDrawerTimePicker;
