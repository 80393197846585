import React from "react";
import { useRbac } from "features/rbac/rbac";
import { getRandomValue } from "utils/commonFunction";

const TableDataHeader: React.FC<{
  headers: any[];
  data: undefined | null | Array<{ [key: string]: any }>;
  calculateStickyProperty: Function;
  otherData?: any;
}> = ({ headers, data, calculateStickyProperty, otherData }) => {

  const { hasPermissions } = useRbac();
  const getHeaderElems = (_eachData: any, eachHeader: any) => {
    if (_eachData[eachHeader.dataIndex] !== undefined) {
      if (eachHeader.dataHeaderRender) {
        return eachHeader.dataHeaderRender(
          _eachData[eachHeader.dataIndex],
          { data: { _eachData }, otherData: otherData }
        );
      }
      return _eachData[eachHeader.dataIndex];
    }
    return "";
  }

  return (
    <React.Fragment>
      {data && (
        <React.Fragment>
          {data.map((_eachData: any) => {
            return (
              <tr className="subheader" key={getRandomValue("number")}>
                {headers.map((eachHeader, i) => {
                  
                  return (
                    <React.Fragment key={`${eachHeader.dataIndex}_${i}`}>
                      {(!eachHeader.allowedPermissions || hasPermissions(eachHeader.allowedPermissions)) &&
                        <th
                          className={calculateStickyProperty(eachHeader).class}
                          style={{
                            ...calculateStickyProperty(eachHeader).style,
                            top: 60 
                          }}
                        >
                          { getHeaderElems(_eachData, eachHeader) }
                          TESTSTTS
                        </th>
                      }
                    </React.Fragment>
                  );
                })}
              </tr>
            );
          })}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default TableDataHeader;
