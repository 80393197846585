import { Col, Row, Select } from "antd"

const OffboardingModalHeader = (props: any) => {
    const { modalTitle, revokeOption, setRevokeOption, form } = props;
    if (form.exit_emp_id) {
        return <Row>
            <Col span={8}><div className="modalHeader">{modalTitle}</div></Col>
            <Col span={6} offset={10}>
                <div className="inputGroup">
                    <Select
                        className="selectDropdownForTbl"
                        placeholder="Select"
                        options={[
                            { label: "Revoke All Access Credentials", value: 'revoke-all-access' },
                            { label: "Delete Zoho", value: 'delete-zoho' },
                            { label: "Delete Jira", value: 'delete-jira' },
                            { label: "Delete Workspace user", value: 'delete-workspace-user' },
                            { label: "Mark as Complete", value: 'mark-as-complete' },
                        ]}
                        value={revokeOption}
                        onChange={(value) => setRevokeOption(value)}
                    ></Select>
                </div>
            </Col>
        </Row>
    } else {
        return <div className="modalHeader">{modalTitle}</div>
    }

}

export default OffboardingModalHeader;