import { useHistory } from "react-router";
import { auth } from "utils/auth/google.security";
import { getEncodedData } from "utils/commonFunction";


const SignoutWithMsg = () => {
    const history = useHistory()
     const signOutWithMessage = (value: string) => {
        auth.signOut();
        window.localStorage.removeItem("emailId");
        window.localStorage.removeItem("displayName");
        window.localStorage.removeItem("expirationTime");
        window.localStorage.removeItem("authToken");
        const msg = getEncodedData(value);
        return history.push(`/login?msg=${msg}`);
    };
  return {signOutWithMessage}
}

export default SignoutWithMsg
