import { CurrencyCode } from "models/enums";

export const FormatCurrencyUnit = (
  value: string | number,
  currency: string
) => {
  if (value === " " || value === "" || value === null) {
    return "";
  }
  else if (currency.toUpperCase() === CurrencyCode.Usd) {
    if(typeof value === "number") {
      return <>{+value < 0 ? '-':''}${Math.abs(+value)}</>;
    }
    return <>${value}</>;
  } else if (currency.toUpperCase() === CurrencyCode.Cad) {
    if(typeof value === "number") {
      return (
        <>
          {+value < 0 ? '-':''}<sup>{currency.toUpperCase()}</sup>{Math.abs(+value)}
        </>
      );
    }
    return (
      <>
        <sup>{currency.toUpperCase()}</sup>{value}
      </>
    );
  }
  return <>{value}</>;
};

export const filterCurrencyValue = (currency: string, showCurrencySymbol: boolean = false) => {

  if(!currency) return '-';

  const currencyValue = currency.split(' ')[1];

  if(+currencyValue === 0)
      return <strong>-</strong>
  else {
      if(showCurrencySymbol)
          return '$' + currencyValue;
      else
          return currencyValue;
  }
}