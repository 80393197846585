import { RoutesEnum } from "models/enums";
import { IApiBaseResponse } from "models/interfaces/Iglobal";
import axiosClient from "sharedServices/api/axios.services";

export class WfhService {
  public createWfhRequest = async (WfhRequestData: {}) => {
    return axiosClient.post<IApiBaseResponse<any>>(
      RoutesEnum.POST_WFH_APPLY_REQUEST, WfhRequestData
    );
  };
  public uploadImageToCloud = async (file: FormData) => {
    return axiosClient.post(RoutesEnum.UPLOAD_WORK_FROM_HOME_DOCS, file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  };

  public updateWfhDetails = async (dateDto: {}) => {
    return axiosClient.post<IApiBaseResponse<any>>(
      RoutesEnum.UPDATE_WFH_STATUS, dateDto
    );
  };

  public getWfhHistoryRequests = async (empCode: {}) => {
    return axiosClient.post<IApiBaseResponse<any>>(
      RoutesEnum.GET_WFH_HISTORY_REQUESTS, empCode
    );
  };

  public getWfhRequestData = async (params: {}) => {
    return axiosClient.get<IApiBaseResponse<any>>(
      RoutesEnum.GET_WFH_REQUEST_DATA,
      {
        params
      }
    );
  }
  public deleteWfhRequests = async (params: {}) => {
    return axiosClient.post<IApiBaseResponse<any>>(
      RoutesEnum.DELETE_WFH_REQUEST, params
    );
  }
}