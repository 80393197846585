import useScrollEffect from 'hooks/useScrollEffect'
import React, { useState } from 'react'
import LoadingSpinner from 'utils/sharedComponents/LoadingSpinner'
import NoDataFound from 'views/NoDataFound'

interface tableDataInterface { name: string, timesheet: string[] }
interface ISort { keyToSort: string, direction: string }
interface LeaveReportTableInterface {
    isTableLoading: boolean,
    tableHeaders: any[],
    tableData: tableDataInterface[]
    setTableData: React.Dispatch<React.SetStateAction<any[]>>
}


const LeaveReportTable: React.FC<LeaveReportTableInterface> = ({ isTableLoading, tableHeaders, tableData, setTableData }) => {

    const tableScrollableElement = useScrollEffect()
    const [sort, setSort] = useState<ISort>({ keyToSort: "name", direction: "asc" });


    const getIconClass = (column: string) => {
        if (sort.keyToSort === column) {
            return sort.direction === 'asc' ? 'da iconSort icon-sort-asc' : 'da iconSort icon-sort-desc';
        }
        return 'da iconSort icon-sort-asc';
    }


    function getSortArray(arrayToSort: any, data: ISort) {
        if (data.direction === 'asc') {
            return arrayToSort?.sort((a: any, b: any) => (a[data.keyToSort] > b[data.keyToSort] ? 1 : -1));
        }
        return arrayToSort?.sort((a: any, b: any) => (a[data.keyToSort] > b[data.keyToSort] ? -1 : 1));
    }


    function handleSortClick(header: string) {
        const data = {
            keyToSort: header,
            direction:
                header === sort.keyToSort ? sort.direction === 'asc' ? 'desc' : 'asc' : 'desc'
        }
        setSort(data)
        setTableData(getSortArray(tableData, data))
    }


    return (
        <LoadingSpinner isLoading={isTableLoading}>
            <div className="customTbl userManagementTbl scroll-y tblStickyTHead timeDelayReport" ref={tableScrollableElement}>
                <table className="main-table">
                    <thead>
                        <tr>
                            {
                                tableHeaders.map((curr_header) => {
                                    return (
                                        <th
                                            className={`${(curr_header === 'name') ? 'text-left' : 'text-right'} ${curr_header === 'name' ? 'colLeftSticky colLeftStickyLast' : ''}`}
                                            style={{ minWidth: "50px", paddingRight: `${curr_header === 'updated_on' ? '20px' : '0'}` }}
                                            key={curr_header}>
                                            {curr_header.split('_').join(' ')}
                                            {
                                                (curr_header === 'name')
                                                &&
                                                (
                                                    <span style={{ 'marginLeft': '5px' }}
                                                        onClick={() => handleSortClick(curr_header === 'employee_name' ? 'employee_name' : curr_header)}
                                                        className={getIconClass(curr_header === 'employee_name' ? 'employee_name' : curr_header)}
                                                    >
                                                    </span>
                                                )
                                            }
                                        </th>
                                    )
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            getSortArray(tableData, sort)?.map((emp_leave_data: any) => {
                                return (
                                    <tr key={emp_leave_data.name}>
                                        {
                                            tableHeaders?.map((curr_header, index: number) => {
                                                return (
                                                    <td
                                                        className={`${(curr_header === 'name') ? 'text-left' : 'text-right'} ${curr_header === 'name' ? 'colLeftSticky colLeftStickyLast' : ''}`}
                                                        key={index}
                                                        style={{ minWidth: `${curr_header === 'name' ? '120px' : curr_header === 'updated_on' ? '120px' : '50px'}`, paddingRight: `${curr_header === 'updated_on' ? '20px' : '0'}` }}
                                                    >
                                                        <span>{emp_leave_data[curr_header]}</span>
                                                    </td>
                                                )
                                            })
                                        }
                                    </tr>
                                )
                            })
                        }
                        {(!tableData || tableData.length === 0) && <NoDataFound />}
                    </tbody>

                    <tfoot></tfoot>
                </table>
            </div>
        </LoadingSpinner>
    )
}

export default LeaveReportTable;
