import React, { ChangeEvent } from "react";
import { Button, Drawer, Space, Tooltip} from "antd";
import type { Duration, Moment } from 'moment';
import { IRegularizeDrawerInputProps, IRegularizeDrawerProps } from 'models/interfaces/Regularize/regularizeOptions.model'
import RegularizeDrawerDatePicker from "utils/regularizeDrawer/RegularizeDrawerDatePicker";
import RegularizeDrawerTimePicker from "utils/regularizeDrawer/RegularizeDrawerTimePicker";
import RegularizeDrawerSingleSelect from "utils/regularizeDrawer//RegularizeDrawerSingleSelect";
import RegularizeDrawerTextarea from "utils/regularizeDrawer/RegularizeDrawerTaxtarea";
import moment from "moment";

const RegularizeDrawer:React.FC<IRegularizeDrawerProps> = 
({regularisationData, setRegularisationData, open, setOpen, submitRegularizeData, cancleRegularisation, inTimeAfterOutTime, setInTimeAfterOutTime}) => {
  
  const drawerInputs: IRegularizeDrawerInputProps[] = [
    {
      disable: true,
      defaultValue: true,
      keyName: "attendance_day",
      label: "Attendance Day: ",
      options: [],
      default: moment(),
      type: 'datepicker'
    },
    {
      disable: false,
      defaultValue: false,
      keyName: "checkin_time",
      label: "Check-In Time: ",
      options: [],
      default: moment(),
      type: 'timepicker'
    },
    {
      disable: false,
      defaultValue: false,
      keyName: "checkout_time",
      label: "Check-Out Time: ",
      options: [],
      default: moment(),
      type: 'timepicker'
    },
    {
      disable: false,
      defaultValue: true,
      keyName: "reason",
      label: "Reason",
      options: [
        {label: 'Forgot to Check-In', value: 'Forgot to Checkin'},
        {label: 'Forgot to Check-Out', value: 'Forgot to Checkout'},
      ],
      default: '',
      type: 'singleSelect'
    },
    {
      disable: false,
      defaultValue: false,
      keyName: "descriptrion",
      label: "Descriptrion",
      options: [],
      default: '',
      type: 'textarea'
    }
  ]

  const handleDateChange = (value: Moment | null) => {
    if(value){
      setRegularisationData({...regularisationData, date: moment(value).local()});
    }
  }

  const onCheckInTimeSelect = (value: Moment | null) => {
    let outTime = null, inTime = null, duration: Duration | string = 'invalid time', errMagObj={bool: false, from: '', msg: ''};
    
    if(value){
      inTime = moment(regularisationData.date?.format('YYYY-MM-DD')+" "+value.format('HH:mm')).local();

      if(regularisationData.outTime) outTime = moment(regularisationData.outTime).local();

      if(inTime && outTime && inTime.isAfter(outTime)){
        errMagObj = {bool: true, from: 'checkin_time', msg: '** Checkin time can not be after checkout time'};
      }else if(inTime && outTime){ 
        errMagObj = {bool: false, from: '', msg: ''};
        duration = moment.duration(outTime.diff(inTime));
        const hours: number | string = duration.hours().toString().padStart(2, '0');
        const mins: number | string = duration.minutes().toString().padStart(2, '0');
        duration = `${hours}:${mins}`
      } 
    }else{
      inTime =  null;
      duration = 'invalid time'
    }
    setInTimeAfterOutTime(errMagObj);
    setRegularisationData({...regularisationData, inTime: inTime, duration});
  }
  

  const onCheckOutTimeSelect = (value: Moment | null) => {
    let outTime = null, inTime = null, duration: Duration | string = 'invalid time', errMagObj={bool: false, from: '', msg: ''};
    
    if(value){
      if(regularisationData.inTime) inTime = moment(regularisationData.inTime).local();
      outTime = moment(regularisationData.date?.format('YYYY-MM-DD')+" "+value.format('HH:mm')).local();
      
      if(inTime && outTime && inTime.isAfter(outTime)){
        duration = 'invalid time';
        errMagObj = {bool: true, from: 'checkout_time', msg: '** Checkout time can not be before checkin time'};
      }else if(inTime && outTime){
        duration = moment.duration(outTime.diff(inTime));
        const hours: number | string = duration.hours().toString().padStart(2, '0');
        const mins: number | string = duration.minutes().toString().padStart(2, '0');
        duration = `${hours}:${mins}`;
      }
    }else{
      outTime = null;
      duration = 'invalid time';
    }   
    setRegularisationData({...regularisationData, outTime: outTime, duration});
    setInTimeAfterOutTime(errMagObj);
  }

  const handleReasonChange = (value: any, options: any) => {
    setRegularisationData({...regularisationData, reason: options});
  }
  
  const handleDescChange = (e: ChangeEvent<HTMLTextAreaElement> | undefined) => {
    if(e)
      setRegularisationData({...regularisationData, desc: e.target.value});
  }
  
  const enableSubmit = () => {
    if(regularisationData.inTime 
      && 
      regularisationData.outTime 
      && regularisationData.reason 
      && !inTimeAfterOutTime.bool
    ) return true;
    return false;
  }

  return (
    <Drawer
        title="Regularization"
        placement="right"
        onClose={() => setOpen(false)}
        visible={open}
        className="filterDrawerWrap drawerCustom"
        closeIcon={
          <Tooltip title="Close Filter Drawer" placement="left">
            <i className="da icon-arrow-right-dark"></i>
          </Tooltip>
        }
      >
        {
          drawerInputs.map((curr, index) => {

            if(curr.type=== 'datepicker'){
              return( 
                <RegularizeDrawerDatePicker 
                  drawerInput={curr} 
                  handleChange={handleDateChange}  
                  RegularisationData={regularisationData} 
                  setRegularisationData={setRegularisationData}
                  key={curr.keyName}
                />
              )
            }

            if(curr.type=== 'timepicker'){
              return( 
                <RegularizeDrawerTimePicker drawerInput={curr} 
                  handleChange={curr.keyName === 'checkin_time'? onCheckInTimeSelect: onCheckOutTimeSelect} 
                  RegularisationData={regularisationData}
                  setRegularisationData={setRegularisationData}
                  key={curr.keyName}
                  inTimeAfterOutTime={curr.keyName === inTimeAfterOutTime.from? inTimeAfterOutTime : {bool:false, from: '', msg: ''}}
                />
              )
            }

            if(curr.type=== 'singleSelect'){
              return( 
                <div key={curr.keyName}>
                  <div 
                    className="flx alignItemsCenter" style={{gap: '.5rem'}} 
                    key={curr.label}>
                      <label htmlFor="">Total Hours:</label>
                      <span 
                        style={{marginBottom: '8px', color: `${regularisationData.duration === 'invalid time'? '#ca1640' : '#212833'}`}}
                      >
                        {regularisationData.duration}
                      </span>
                  </div>

                  <RegularizeDrawerSingleSelect 
                    drawerInput={curr} 
                    handleChange={handleReasonChange}
                    RegularisationData={regularisationData}
                    setRegularisationData={setRegularisationData}
                  />
                </div>
              )
            }

            if(curr.type === 'textarea'){
              return( 
                <RegularizeDrawerTextarea 
                  key={curr.keyName}
                  drawerInput={curr} 
                  handleChange={handleDescChange} 
                  RegularisationData={regularisationData}
                  setRegularisationData={setRegularisationData}
                />
              )
            }
          })
        }
        
        <Space wrap className="btnsRight">
          <Button type="link" onClick={cancleRegularisation} className="linkBtn">Cancel</Button>
          <Button type="primary" onClick={submitRegularizeData} disabled={!enableSubmit()}>Submit</Button>
        </Space>

      </Drawer>
  );
};

export default RegularizeDrawer;