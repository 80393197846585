import { Input, Select } from "antd";

const HiringSource = (props: any) => {
    let { customHiring, candidate_info, handleBlur, form, setCustomHiring, handleChangeSelect } = props;
    if (customHiring) {
        return <Input
            placeholder="Enter New Source"
            type="text"
            onBlur={(event) => {
                handleBlur(event.target.value, "candidate_source")
            }}
            id="candidate_source"
            // value={form.candidate_source}
            className="ant-input"
            autoComplete="false"
        />
    } else {
        return <Select
            placeholder="Select"
            options={[...candidate_info, { label: 'Add New', value: 0 }]}
            onChange
            ={(value) => {
                if (value === 0) {
                    handleChangeSelect(null, "candidate_source")
                    setCustomHiring(true);
                } else {
                    handleChangeSelect(value, "candidate_source")
                }
            }
            }
            value={form.candidate_source}
            allowClear
        ></Select>
    }
}

export default HiringSource;