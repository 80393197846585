export const ConstantMsg = {
    LOADING_CHECKING_EMAIL_ID: {
        content: "validating Email...",
        duration: 0,
        key: 'checkEmail',
        className: "toastMsg loadingMsg",
    },

    NOT_PRESENT_CHECKING_EMAIL_ID: {
        content: "Email id not present",
        key: 'checkEmail',
        duration: 5,
        className: "toastMsg savedSuccess",
    },
    
    ERROR_CHECKING_EMAIL_ID: {
        content: "Error in checking email!",
        key: 'checkEmail',
        duration: 5,
        className: "toastMsg savedError",
    },

    PRESENT_CHECKING_EMAIL_ID: {
        content: "Email id already present",
        key: 'checkEmail',
        duration: 5,
        className: "toastMsg savedError",
    },

    LOADING_SAVE_MASTER_LEAVE_DATA: {
        content: "Saving new Master data...",
        duration: 0,
        key: 'saveJoinee',
        className: "toastMsg loadingMsg",
    },

    SUCCESS_SAVE_MASTER_LEAVE_DATA: {
        content: "New leave data saved successfully!",
        key: 'saveJoinee',
        duration: 5,
        className: "toastMsg savedSuccess",
    },

    ERROR_SAVE_MASTER_LEAVE_DATA: {
        content: "New leave data not saved!",
        key: 'saveJoinee',
        duration: 5,
        className: "toastMsg savedError",
    },
    LOADING_CREATE_CURRENT_FINANCIAL_YEAR_COLUMN: {
        content: "Trying to create current financial year column.",
        duration: 0,
        key: 'createCurrentFinancialYearColumn',
        className: "toastMsg loadingMsg",
    },

    SUCCESS_CREATE_CURRENT_FINANCIAL_YEAR_COLUMN: {
        content: "Successfully created current financial year column!",
        key: 'createCurrentFinancialYearColumn',
        duration: 5,
        className: "toastMsg savedSuccess",
    },

    ERROR_CREATE_CURRENT_FINANCIAL_YEAR_COLUMN: {
        content: "Error occurred during current financial year column creation!",
        key: 'createCurrentFinancialYearColumn',
        duration: 5,
        className: "toastMsg savedError",
      }
}