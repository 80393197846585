import React from "react";
import { Select } from "antd";
import { ILeaveReconciliationFilterParams, ILeaveReconciliationVersionSelect } from "models/interfaces";

interface i {
  tempFilterParams: ILeaveReconciliationFilterParams, 
  dropdownOptions: ILeaveReconciliationVersionSelect [], 
  keyName: string, 
  onChange: (key: string, value: string[]) => void
}


const LeaveReconciliationSingleSelect: React.FC<i> = ({ dropdownOptions, keyName, tempFilterParams, onChange }) => {
  const handleChange = (value: string[]) => {
    onChange(keyName, value)
  };

  return (
    <React.Fragment>
      <div className="inputWrap">
        <label>{'Select' + " " + keyName}</label>
        <Select
          options={dropdownOptions}
          placeholder="Please select"
          showSearch={true} 
          value={tempFilterParams[keyName.toLowerCase()] || dropdownOptions[dropdownOptions.length-1].value}
          onChange={handleChange}
          allowClear
        />
      </div>
    </React.Fragment>
  );
};

export default LeaveReconciliationSingleSelect;
