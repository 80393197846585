import { RoutesEnum } from "models/enums";
import { IApiBaseResponse } from "models/interfaces/Iglobal";
import axiosClient from "sharedServices/api/axios.services";

export class CalendarPresetService {
  public getCalendarPresetData = async () => {
    return axiosClient.get<IApiBaseResponse>(
      RoutesEnum.CALENDAR_PRESETS
    );
  };
}
