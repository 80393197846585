export class AppSetting {

    //Time settings
    static TIMEZONE = "Asia/Kolkata";
    static DB_DATE_FORMAT = "YYYY-MM-DD";
    static DEFAULT_PO_FROM = "2018-11-12";
    static DEFAULT_EVENT_TO = "2099-12-31";
    static CLIENT_ZONE = "Asia/Kolkata";
    static REPORT_DATE_FORMAT = "MM-DD-YYYY";
    static EVENT_DATE_FORMAT = "MM-DD-YYYY, hh:mm a";

    //Pagination settings
    static DEFAULT_PAGE_INDEX = 1;
    static PAGE_SIZE = 100;

    /** Idle Time Settings */
    static IDLE_TIME_TIMEOUT_IN_SECONDS = 5;

    //Skybox Hyperlinks
    static EVENT_URL = "https://skybox.vividseats.com/inventory?pageNumber=1&ef.eventId=";
    static PURCHASE_ID_URL = "https://skybox.vividseats.com/purchases/";
    static INVOICE_ID_URL = "https://skybox.vividseats.com/invoices/";
}