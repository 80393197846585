import { Layout } from "antd";
import { usePageTitle } from "hooks";
import React from "react";
import AccessDenied from "./AccessDenied";
export const Forbidden: React.FC<{
  isAccessFromPage?: boolean;
}> = ({ isAccessFromPage }) => {
  usePageTitle("HTTP 403 Forbidden");
  const contain = () => {
    return (
      <Layout className="mainInnerLayout" style={{ height: "100%" }}>
        <h3>HTTP 403 Forbidden</h3>
        <AccessDenied isAccessFromPage={isAccessFromPage} />
      </Layout>
    );
  };

  if (isAccessFromPage) {
    return <React.Fragment>{contain()}</React.Fragment>;
  } else {
    return contain();
  }
};
