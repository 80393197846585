import { Input, Select } from "antd";
import { department_designation_value } from './department_designation_value_json';
import { Option } from "antd/lib/mentions";

const Designation = (props: any) => {
    let { customDesignation, designation_info, handleBlur, form, setCustomDesignation, handleChangeSelect} = props;
    if (customDesignation) {
        return <Input
            placeholder="Enter New Source"
            type="text"
            onBlur={(event) => {
                handleBlur(event.target.value, "designation")
            }}
            id="designation"
            className="ant-input"
            autoComplete="false"
        />
    } else {


        return <Select
            placeholder="Select"
            // options={[...designation_info, { label: 'Add New', value: 0 }]}
            // options={[...designation_info]}
            onChange
            ={(value: any) => {

                function getKeyByValue() {
                    for (let key in department_designation_value) {
                        if (department_designation_value[key].includes(value)) {
                            return Number(key);
                        }
                    }
                    return null; // or any default value if the number is not found in any array
                }

                let key = getKeyByValue();
                // if (value === 0) {
                //     handleChangeSelect(null, "designation")
                //     setCustomDesignation(true);
                // } else {
                //     handleChangeSelect(value, "designation")
                // }
                handleChangeSelect(value, "designation")
                handleChangeSelect(key, "department")


            }
            }
            value={form.designation}
            allowClear
        >

            {designation_info

            // for hiding options-----

                // .filter((item:any) => {
                //     if (form.department !== undefined && form.department !== null) {
                //         return department_designation_value[form.department].includes(item.value);
                //     }
                //     return true;
                // })
                .map((item:any) => (
                    <Option key={item.value} value={item.value}>
                        {item.label}
                    </Option>
                ))}

                {/* for disabling options ----- */}

            {/* {designation_info.map((item: any) => {
                const getKeyByValue = () => {
                    if(form.department !== undefined )
                        return department_designation_value[form.department]
                    else{
                        return null;
                    }
                    
                };

                const key = getKeyByValue()


                // const allowedValues = key ? department_designation_value[key] : [];


                // const isDisabled = !key?.includes(item.value);
                let isDisabled = false;
                let items;

                if(key !== null && key !== undefined && !key?.includes(item.value)){
                    isDisabled = true
                    items = item
                }
              
                return (
                    <Option key={item.value} value={item.value} disabled={isDisabled}>
                        {item.label}
                    </Option>
                )
            })} */}
        </Select>
    }
}
export default Designation;