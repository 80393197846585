import React, { useLayoutEffect, useRef } from 'react'

const useScrollEffect = () => {
  const tableScrollableElement = useRef<HTMLDivElement | null>(null)

    useLayoutEffect(() => {
        function updateScrollPosition() {
            if (
                tableScrollableElement?.current &&
                tableScrollableElement?.current?.scrollLeft > 0
            ) {
                tableScrollableElement.current?.classList.add("scrolledShadow");
            }
            if (
                tableScrollableElement.current &&
                tableScrollableElement.current?.scrollLeft === 0
            ) {
                setTimeout(() => {
                    tableScrollableElement.current?.classList.remove("scrolledShadow");
                }, 100);
            }
        }
        tableScrollableElement.current?.addEventListener(
            "scroll",
            updateScrollPosition
        );
    });
    return tableScrollableElement
}

export default useScrollEffect
