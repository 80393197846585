import React from 'react';
import { DatePicker } from 'antd';
import type { Moment } from 'moment';
import { IWFHData, IWfhDrawerInputProps } from 'models/interfaces/WfhApply/wfhApply.model';

interface IWfhInputDatePicker{
    drawerInput: IWfhDrawerInputProps
    handleChange: (keyName: string, value: any) => void
    wfhData: IWFHData;
    disabledDate?: (current: Moment | null) => boolean;
  }

const WfhDrawerDatePicker:React.FC<IWfhInputDatePicker> = ({drawerInput, handleChange, wfhData, disabledDate}) => {
  
  return (
    <div className="inputWrap">
        <label>{drawerInput.label} <span className="req">*</span> </label>
        <DatePicker
          disabled = {false}
          format={'DD-MM-YYYY'}
          value={wfhData[drawerInput.keyName]} 
          onChange={(value: Moment | null) => handleChange(drawerInput.keyName, value)}
          disabledDate={disabledDate}
          allowClear
          showToday={false}
        />
      </div>
  )
}

export default WfhDrawerDatePicker;
