import Routes from "routes/Routes";
import "App.css";
import "assets/css/common-style.css";
import "assets/css/custom-style.css";
import "assets/css/customTable.css";
import "assets/css/custom-dev-style.css";
import "assets/css/responsive.css";
import AppLayout from "layout/AppLayout";


const App = () => {

  
  return (
    <AppLayout>
      <Routes />
    </AppLayout>
  );
};

export default App;
