import { RangeValue } from "models/types/Reports/DateRangeSelector";
import { useCallback, useEffect, useMemo, useState } from "react";
import moment, { Moment } from 'moment';
import { Constants } from "views/constants";
import DateRangePicker from 'views/Reports/DateRangePicker';
import { DownloadOutlined } from "@ant-design/icons";
import { usePageTitle } from "hooks";
import { Button, Input, Layout } from "antd";
import ExcelJS, { Border, Fill } from 'exceljs';
import CommonTable from "./CommonTable";
import { useApiServices } from "hooks/useApiServices";
import { getSortArray } from "utils/commonFunction";
import MultiStateButton from "utils/components/MultiStateButton";

export const bgColorMapping = {
    'Team Member': 'cee2f3',
    'Out of Bengal': 'fce5cd',
    'Inactive': 'b10202',
    'Short Term WFH': 'efbd08',
    'Permanent WFH': 'd5a6bd',
    null: null
}
export const colorMapping = {
    // 'Team Member': 'cee2f3',
    'Out of Bengal': '000000',
    'Inactive': 'ffffff',
    'Short Term WFH': 'ff0000',
    'Permanent WFH': 'ff0000',
    null: null
}



interface ITableData {
    employee_name: string;
    bg_color: string | null;
    grandTotal: string;
    totalHoursData: object;
    working_status: string | null;
    totalHours: number;
    totalMinutes: number;
    empWfhDates: string[];
}

interface IDurationData {
    date_duration: any;
    employee_code: string;
    employee_name: string;
    working_status: string;
}


const TotalHoursReport = () => {

    usePageTitle(Constants.TOTAL_HOURS_TITLE);
    const { isLoading, isError, data: totalHoursData, fetchData } = useApiServices()
    const [currButtonState, setCurrButtonState] = useState<'Total Hours' | 'In/ Out'>('In/ Out')

    const currentDate = moment();
    const firstDayOfMonth = moment().startOf('month');
    const lastDayOfPreviousMonth = moment().subtract(1, 'months').endOf('month');


    const [selectedRange, setSelectedRange] = useState<RangeValue>(null);
    const [startDate, setStartDate] = useState<string>(() => {
        if (currentDate.date() === 1) {
            return currentDate.subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
        } else {
            return firstDayOfMonth.format('YYYY-MM-DD');
        }
    })
    const [endDate, setEndDate] = useState<string>(() => {
        if (currentDate.date() === 1) {
            return lastDayOfPreviousMonth.format('YYYY-MM-DD');
        } else {
            return currentDate.subtract(1, 'days').format('YYYY-MM-DD');
        }
    })


    const [finalDates, setFinalDates] = useState<RangeValue>(null);
    const [dates, setDates] = useState<RangeValue>(null);
    const [diffMonth] = useState<boolean>(false);
    const [isDropdownOpened, setIsDropdownOpened] = useState<boolean>(false);
    const [tableData, setTableData] = useState<ITableData []>([]);

    const [filterParams, setFilterParams] = useState<{searchText: string, searchColumns: string []}>({searchColumns: ['employee_name'], searchText: ''});
    const [searchTimeOut, setSearchTimeOut] = useState<any>(null);



    const tableHeaders: string[] = useMemo(() => {
        const monthAbbreviation = new Intl.DateTimeFormat('en-US', { month: 'short' });
        const currentDate = moment(startDate);
        const end = moment(endDate);
        const isSame = isSameMonth(startDate, endDate);
        const dayArr: any[] = ['name'];

        while (currentDate.isSameOrBefore(end, 'day')) {
            const month = monthAbbreviation.format(currentDate.toDate());
            const date = currentDate.date();
            let formattedDate = ''
            if (!isSame) {
                formattedDate = `${month} ${date.toString().padStart(2, '0')}`;
            }
            else {
                formattedDate = String(currentDate.date())
            }
            dayArr.push(formattedDate);
            currentDate.add(1, 'day');
        }
        
        if(currButtonState !== 'Total Hours')
            dayArr.push('grand_total');

        return dayArr;
    }, [startDate, endDate, currButtonState]);




    const handleRangeChange = (range: RangeValue) => {
        if (range?.[0] && range[1]) {
            setStartDate(range[0].format('YYYY-MM-DD'));
            setEndDate(range[1].format('YYYY-MM-DD'));
        }
        setSelectedRange(range);
    }


    const disabledDate = (current: Moment) => {
        if (current.isSame(moment(), 'day')) return true;
        if (current.isAfter(moment(), 'day')) return true;
        return false;
    };


    const handleCalanderChange = (range: RangeValue) => {
        if (range?.[0] && range[1]) {
            setFinalDates(range);
            setDates(null);
            setIsDropdownOpened(false)
            return;
        }
        setDates(range)
    }


    const onPanelChange = (value: RangeValue) => { }

    const toggleButtonState = () => currButtonState === 'Total Hours'? 'In/ Out' : 'Total Hours';    
    const formatDate = useCallback((key: string) => {
        const date = moment(key, 'YYYY-MM-DD');
        let curr_day;
        let isSame = isSameMonth(startDate, endDate);
        const monthAbbreviation = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date.toDate());
        const formattedDate = `${monthAbbreviation} ${date.date().toString().padStart(2, '0')}`;
        if (!isSame) {
            curr_day = formattedDate;
        } else {
            curr_day = key.split('-')[2]
            if (curr_day.startsWith('0')) {
                curr_day = curr_day.charAt(1)
            }
        }
        return curr_day
    }, [startDate, endDate]);


    const findCurrDayWorkingMode = (date: Moment, working_mode: string, employee_wfh_data: any [] = []) => {
        let curr_day_working_mode = working_mode;

        employee_wfh_data.forEach(({ from_date, to_date, days }: any) => {
            let start = moment(from_date);
            const end = moment(to_date);
            while (!start.isAfter(end)) {
                const weekDay = start.weekday();
                if (date.isSame(start) && (!days || (days?.includes(weekDay)))) {
                    curr_day_working_mode = 'short term wfh'
                }
                start = start.add(1, 'days')
            }
        });
        
        return curr_day_working_mode
    }

    const getFinalDuration = (data: string | {in_time: string, out_time: string}, working_mode: string) => {

        if(typeof (data) === 'string'){
            let final_duration = data.split(':').splice(0, 2);
            let hour_str, min_str;

            if (final_duration[0].startsWith('0')) {
                hour_str = final_duration[0].charAt(1);
                min_str = final_duration[1];
    
            } else {
                hour_str = final_duration[0];
                min_str = final_duration[1];
            }
            return {
                hour_str, min_str,
                cellValue: `${hour_str}h ${min_str}m`
            }
        }
        
        
        return {
            cellValue: 
            (data.in_time || data.out_time)?
                {
                    in_time: data.in_time,
                    out_time: data.out_time
                }
                : 
                    null
        }
    }


    const structureData = useCallback((duration_info: IDurationData[], wfh_data: any, filterParams: { searchText: string, searchColumns: string[] }) => {

        let updated_obj: ITableData [] = [];
        duration_info?.forEach(({ employee_name: name, employee_code, date_duration, working_status }) => {
            console.log(name);
            

            const employee_wfh_data = wfh_data?.[employee_code]
            let totalHours = 0;
            let totalMinutes = 0;
            let employee_total_hours_obj = { name }
            let empWfhDates: string[] = []
            let allNull = true;

            Object.keys(date_duration)?.forEach((key: any) => {
                const date = moment(key, 'YYYY-MM-DD');
                const curr_day = formatDate(key)
                let work_type = findCurrDayWorkingMode(date, working_status, employee_wfh_data)


                // console.log(work_type, working_status);
                
                if (work_type !== working_status) {
                    empWfhDates.push(curr_day);
                }

                const curr_date_data = getFinalDuration(date_duration[key], work_type);

                if(curr_date_data.cellValue !== null)
                    allNull = false


                if (typeof(date_duration[key]) === 'string'){
                    totalHours += Number(curr_date_data.hour_str)
                    totalMinutes += Number(curr_date_data.min_str)
                }
                employee_total_hours_obj[curr_day] = curr_date_data.cellValue
            });

            while (totalMinutes >= 60) {
                totalHours++;
                totalMinutes -= 60;
            }

            const mins = (totalMinutes >= 0 && totalMinutes <= 9) ? `0${totalMinutes}` : totalMinutes;
            employee_total_hours_obj['grand_total'] = `${totalHours}h ${mins}m`;
            let obj: ITableData = {
                employee_name: name,
                grandTotal: `${totalHours}h ${mins}m`,
                totalHours,
                totalMinutes: totalMinutes,
                working_status: working_status,
                totalHoursData: employee_total_hours_obj,
                bg_color: bgColorMapping[`${working_status}`],
                empWfhDates: empWfhDates
            }


            if(!allNull)
                updated_obj.push(obj)
        });


        if (filterParams.searchText !== '') {
            updated_obj = updated_obj.filter((curr_row: any) => {
                return filterParams.searchColumns.find((curr_col) => {
                    return curr_row[curr_col]?.toLowerCase()?.includes(filterParams.searchText.toLowerCase())
                })
            })
        }

        return getSortArray(updated_obj, { keyToSort: 'employee_name', direction: 'asc' })
    }, [formatDate])
    

    function isSameMonth(startDateStr: any, endDateStr: any) {
        return (
            moment(startDateStr, 'YYYY-MM-DD').isSame(endDateStr, 'month') &&
            moment(startDateStr, 'YYYY-MM-DD').isSame(endDateStr, 'year')
        )
    }

    function handleSearch(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        if (searchTimeOut) {
            clearTimeout(searchTimeOut);
        }
        const value: string = e.target.value;
        const ele: any = e.nativeEvent;
        const key: any = ele.data;
        const inputType = ele.inputType;

        if (
            key !== null &&
            inputType !== "deleteContentBackward" &&
            (value === "" || value?.length < 3)
        ) {
            setFilterParams((prev: any) => ({...filterParams, searchText: ''}));
            return;
        }
        setSearchTimeOut(
            setTimeout(
                () => {
                    setFilterParams((prev: any) => ({...filterParams, searchText: value}));
                },
                1500
            )
        );
    }


    const fileName = () => {
        const currentDate = new Date();
        const day = String(currentDate.getDate()).padStart(2, '0');
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const year = currentDate.getFullYear();
        const formattedDate = `${day}-${month}-${year}`;
        const filename = `total_hours_biometric_report_${formattedDate}.xlsx`;
        return filename;
    }


    const getEmpWorkingStatus = (name: any) => {
        const employee = tableData.find(emp => emp.employee_name === name);
        if (employee) {
            return {
                emp_working_status: employee.working_status,
                bg_color: employee.bg_color
            }
        } else {
            return {
                emp_working_status: null,
                bg_color: null
            }
        }
    }


    const getBackgroundValues = (emp_working_status: string | null) => {
        const g: Fill = {
            type: 'pattern',
            pattern: "solid",
            fgColor: { argb: bgColorMapping[`${emp_working_status}`] }
        };
        return g;
    }

    const getExcelSheetHeaders = (startDateObj: moment.Moment, endDateObj: moment.Moment) => {
        const xlsxHeader: string[] = []
        while (startDateObj.isSameOrBefore(endDateObj, 'day')) {
            const formattedDate = startDateObj.format('DD-MM-YYYY');
            xlsxHeader.push(formattedDate);
            startDateObj.add(1, 'day');
        }
        return ['Employee Name', ...xlsxHeader, 'Grand Total'];
    }


    const exportToExcelFile = () => {
        const workbook = new ExcelJS.Workbook();
        const sheet = workbook.addWorksheet("Total Hours Report");
        let col_array: Partial<ExcelJS.Column>[] | { header: any; key: any; width: number; }[] = [];
        const headers = getExcelSheetHeaders(moment(startDate), moment(endDate));

        headers.forEach((header: any) => {
            let width = 25
            if(currButtonState === 'Total Hours'){
                if(header !== 'Employee Name')
                    width = 22
            }else{
                if(header !== 'Employee Name')
                    width = 12
            }
            let obj = {
                header: header,
                key: header,
                width
            }
            col_array.push(obj);
        });

        sheet.columns = col_array;

        sheet.getRow(1).eachCell((cell) => {
            const cellValue = cell?.value;
            const border: Partial<Border> = { style: 'thin', color: { argb: 'ffe1e1e1' } }

            if (cellValue === 'Employee Name') {
                cell.font = { italic: true }
                cell.fill = {
                    type: 'pattern',
                    pattern: "solid",
                    fgColor: { argb: 'fff4f6f8' }
                };
            }

            if (cellValue !== "Employee Name" && cellValue !== '') {
                cell.fill = {
                    type: 'pattern',
                    pattern: "solid",
                    fgColor: { argb: 'ff8093b3' }
                };

                cell.font = {
                    color: { argb: 'FFFFFF' },
                    size: 11
                }
                cell.alignment = { horizontal: 'right' };
            }
            cell.border = { top: border, left: border, bottom: border, right: border };
        })

        let wfh_obj = {};

        // Preparing the table data
        let rows_arr: any[] = tableData.map((item: any, index: number) => {
            const durations_array = item['totalHoursData'];
            if (item['empWfhDates'].length > 0)
                wfh_obj[index + 1] = item['empWfhDates'];
            
            let duration = tableHeaders.map((key: string) => {
                
                let value = ''
                if(durations_array[key] && typeof(durations_array[key]) === 'string'){
                    value = durations_array[key]
                }
                else if((durations_array?.[key]?.in_time && durations_array[key]?.out_time)){
                    value = `In: ${durations_array[key]?.in_time}, Out: ${durations_array[key]?.out_time}`
                }
                else if(durations_array?.[key]?.in_time || durations_array[key]?.out_time){
                    value = `In: ${durations_array[key]?.in_time ?? '-'}, Out: ${durations_array[key]?.out_time ?? '-'}`
                }
                
                return value;
            });
            
            return duration;
        });
        


        for (const element of rows_arr) {
            let array = element;
            const border: Partial<Border> = { style: 'thin', color: { argb: 'ffe1e1e1' } }
            const rowObject = {};

            for (let j = 0; j < col_array.length; j++) {
                if (tableData.length > 0) {
                    rowObject[col_array[j].key] = array[j]
                }
            }

            const row = sheet.addRow(rowObject)
            row.alignment = { vertical: 'top' };
            const firstCell = row.getCell(1)
            firstCell.fill = {
                type: 'pattern',
                pattern: "solid",
                fgColor: { argb: 'fff4f6f8' }
            };
            firstCell.border = { top: border, left: border, bottom: border, right: border };
        }

        sheet.getRow(1).alignment = { vertical: 'top' };


        for (let i = 2; i < headers.length; i++) {
            let col = sheet.getColumn(i);
            
            const border: Partial<Border> = { style: 'thin', color: { argb: 'e1e1e1' } }
            col.eachCell((cell, rowNumber) => {
                let cellValue = cell.value;

                let firstColumnCellValue = sheet.getCell(`A${cell.row}`).value;
                let emp_status = getEmpWorkingStatus(firstColumnCellValue);
                let emp_working_status = emp_status?.emp_working_status;
                


                if (emp_working_status === 'Inactive') {
                    cell.value = null
                    cell.font = {
                        color: { argb: 'b10202' }
                    }
                    cell.fill = getBackgroundValues(emp_working_status)
                }
                else if (cellValue === '0h 00m') {
                    const isWfh = wfh_obj[rowNumber - 1]?.includes(String(i - 1));
                    cell.fill = isWfh ? getBackgroundValues('Short Term WFH') : getBackgroundValues(emp_working_status)

                    if (emp_working_status !== null) {
                        cell.font = {
                            size: 11,
                            color: { argb: colorMapping[isWfh? 'Short Term WFH' : emp_working_status] ?? '000000'}
                        }
                    }
                }

                cell.border = { top: border, left: border, bottom: border, right: border };
                cell.alignment = { horizontal: 'left' };
            })
        }

        workbook.xlsx.writeBuffer().then((data: any) => {
            const blob = new Blob([data], {
                type: "application/vvnd.openxmlformats-officedocument.spreadsheet.sheet"
            });

            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = fileName();
            anchor.click();
            window.URL.revokeObjectURL(url);
        })
    }


    const entries = Object.entries(bgColorMapping);

    const handleButtonStateChange = (e: any) => setCurrButtonState(toggleButtonState())


    useEffect(() => {
        if(totalHoursData){
            const structuredData = structureData(totalHoursData?.duration_info, totalHoursData?.employee_wfh_data, filterParams);
            setTableData(structuredData)
        }
    }, [totalHoursData, structureData, filterParams])

    useEffect(() => {
        fetchData('GET_BIOMETRIC_TOTAL_HOURS', { "start_day": `${startDate}`, "end_day": `${endDate}`, state: currButtonState === 'Total Hours'? 'inOut' : 'duration' })
        const header = document.getElementsByTagName('h2')[0];
        
        if(currButtonState === 'Total Hours' && header.innerText === 'Total Hours'){
            header.innerText = 'Check-In/ Check-Out';
        }
        else if(currButtonState === 'In/ Out' && header.innerText === 'Check-In/ Check-Out')
            header.innerText = 'Total Hours';
    }, [startDate, endDate, fetchData, currButtonState]);


    return (
        <>
            {
                isError ? (
                    <>
                        <h2>{isError.code} Error</h2>
                        <div className="errorContent">
                            <div className="errorContentInner">
                                <span className="error-img"></span>
                                <div>
                                    <h3>{isError.message}</h3>
                                    <p>{Constants.BACK_TO_PREVIOUS_PAGE}</p>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <Layout className="mainInnerLayout totalHoursReport">
                        <h2>{Constants.TOTAL_HOURS_REPORT}</h2>
                        <div className="innerHeader" >
                            <div className="ant-row alignItemsCenter flx justifyContentBetween alignItemsCenter">
                                <div className="ant-col ant-col-xl-4 flx justifyContentBetween alignItemsCenter">
                                    {isSameMonth(startDate, endDate) ?
                                        <h3>{moment(startDate).format('MMM, YYYY')}</h3>
                                        : <h3>{moment(startDate).format('MMM, YYYY')} to {moment(endDate).format('MMM, YYYY')}</h3>}
                                </div>
                                <div className="ant-col ant-col-xl-12 flx justifyContentBetween alignItemsCenter">
                                    <Input
                                    placeholder={Constants.SEARCH_LABEL_TOTAL_HOURS_REPORT}
                                    disabled={isLoading}
                                    allowClear
                                    className="searchField"
                                    onChange={handleSearch}
                                    />
                                </div>
                                <div className='flx ant-col-xl-8' style={{ justifyContent: 'flex-end' }}>
                                    <div className="innerHeaderRight flx alignItemsFlexEnd">
                                        <MultiStateButton value={currButtonState} onChange={handleButtonStateChange} disabled={isLoading} />
                                        <div style={{ marginRight: '16px' }}>
                                            <DateRangePicker
                                                disabled={isLoading}
                                                value={selectedRange}
                                                handleRangeChange={handleRangeChange}
                                                disabledDate={disabledDate}
                                                dates={dates}
                                                setDates={setDates}
                                                finalDates={finalDates}
                                                setFinalDates={setFinalDates}
                                                diffMonth={diffMonth}
                                                handleCalanderChange={handleCalanderChange}
                                                onPanelChange={onPanelChange}
                                                isDropdownOpened={isDropdownOpened}
                                                setIsDropdownOpened={setIsDropdownOpened}
                                            />
                                        </div>
                                        <div >
                                            <Button
                                            className="addUserBtn mr-10 custom-export"
                                            disabled={isLoading}
                                            icon={
                                                <DownloadOutlined style={{ color: 'white', 'paddingTop': '3px' }}
                                                onPointerOverCapture={undefined}
                                                onPointerMoveCapture={undefined} />
                                            }
                                            onClick={exportToExcelFile}>
                                                Export to XLSX
                                            </Button>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>

                        <div>
                            <CommonTable
                            tableHeaders={tableHeaders}
                            tableData={tableData}
                            getSortArray={getSortArray}
                            isLoading={isLoading}
                            currButtonState={currButtonState}
                            />
                        </div>
                        {
                            currButtonState === 'In/ Out' && 
                            (
                                tableData?.length > 0 &&
                                (
                                    <ul className="tagList">
                                        {entries
                                            .filter(([key]) => key !== null && key !== 'null')
                                            .map(([key, value], index) => (
                                                <li
                                                    key={index}
                                                    style={{
                                                        backgroundColor: key === 'Team Member' ? '#9caac8' : `#${value}`,
                                                        color: key === 'Inactive' ? 'white' : '#FF0000',
                                                    }}
                                                >
                                                    {key}
                                                </li>
                                            ))}
                                    </ul>
                                )
                            )
                        }
                    </Layout>
                )
            }
        </>

    )
}

export default TotalHoursReport;