import React from "react";
import { useRbac } from "features/rbac/rbac";
import { getRandomValue } from "utils/commonFunction";

const TableDataFooter: React.FC<{
  headers: any[];
  data: undefined | null | Array<{ [key: string]: any }>;
  calculateStickyProperty: Function;
  otherData?: any;
}> = ({ headers, data, calculateStickyProperty, otherData }) => {
  const { hasPermissions } = useRbac();

  const getFooterElems = (_eachData: any, eachHeader: any) => {
    if (_eachData[eachHeader.dataIndex] !== undefined) {
      if (eachHeader.dataFooterRender) {
        return eachHeader.dataFooterRender(
          _eachData[eachHeader.dataIndex],
          { data: { _eachData }, otherData: otherData }
        );
      }
      return _eachData[eachHeader.dataIndex];
    }
    return "";
  }
  
  return (
    <React.Fragment>
      {data && (
        <tfoot>
          {data.map((_eachData: any) => {
            return (
              <tr key={getRandomValue("number")}>
                {headers.map((eachHeader) => {
                  return (
                    <React.Fragment>
                      {(!eachHeader.allowedPermissions || hasPermissions(eachHeader.allowedPermissions)) &&
                        <th
                          key={eachHeader.dataIndex}
                          className={
                            calculateStickyProperty(eachHeader).class
                              ? calculateStickyProperty(eachHeader).class
                              : "col"
                          }
                          style={calculateStickyProperty(eachHeader).style}
                        >
                          { getFooterElems(_eachData, eachHeader) }
                        </th>
                      }
                    </React.Fragment>
                    
                  );
                })}
              </tr>
            );
          })}
        </tfoot>
      )}
    </React.Fragment>
  );
};

export default TableDataFooter;
